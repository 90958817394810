/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.ProductConfigurableAttributesDetails {
    &-Attribute {
        font-size: 1.4rem;
        @include desktop {
            margin-right: 1.2rem;
        }
        &:after {
            @include desktop {
                content: "|";
                margin-left: 1.2rem;
                display: inline-block;
            }
        }
        &:last-child {
            &:after {
                @include desktop {
                    display: none;
                }
            }
        }
    }
    &-AttributeLabel {
        font-weight: 600;
        margin-right: 1rem;
        @include desktop {
            margin-right: .3rem;
        }
    }
    &-Wrapper {
        @include desktop {
            display: flex;
            flex-wrap: wrap;
            max-width: 550px;
        }
        .TextPlaceholder {
            &_length_custom  {
                &:after {
                    content: "";
                    height: 75px;
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}