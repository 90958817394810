/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.VariantProductCard {
    &-ProductCard {
        &:hover {
            cursor: pointer;
        }
        .ProductCard {
            &-Link {
                margin-bottom: 15px;
                font-size: 1.2rem;
            }
            &-Content {
                padding: 10px;
                padding-bottom: 0;
            }
            &-Price {
                font-size: 1.6rem;
            }
            &-Badges {
                pointer-events: none;
            }
            &-PriceWrapper {
                margin-top: 10px;
            }
        }
    }
    &-Attribute {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 1.2rem;
    }
    &-AttributeLabel {
        font-weight: 600;
    }

    &-AttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
    }

}