.StoresList {
    @include image-reset;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2.4rem;
    row-gap: 3.6rem;
    padding-top: 2.4rem;
    margin-bottom: 120px;

    @include mobile {
        display: block;
        margin-bottom: 4rem;
    }

    @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @include desktop {
        padding-top: 3.6rem;
    }

    &-Store {
        height: 100%;
        display: flex;
        flex-direction: column;

        @include phone {
            margin-bottom: 3.6rem;
        }

        .Image.Image_hasSrc {
            height: 320px !important;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                //background-color: rgba(245, 241, 231, 0.8);
            }
            .Image-Image {
                max-width: 100%;
                width: 100%!important;
                height: 100%!important;
                object-fit: cover!important;
                position: relative!important;
                transition: ease-in all .2s;
            }
        }
        &:hover {
            .Image-Image {
                transform: scale(1.1);
                transition: ease-in all .2s;
            }
        }
    }

    &-StoreContent {
        position: relative;
        width: 100%;
        z-index: 3;

        h3 {
            margin-top: 1.2rem;
            margin-bottom: 0;
            font-weight: 500;
        }

    }

    &-Link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 5;
    }

}