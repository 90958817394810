/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.ProductCard {

    --price-with-discount-color: #000;

    background-color: var(--image-background-color);
    box-shadow: 2px 5px 15px #0000000d;
    flex-direction: column;

    &-Content {
        padding: 20px;
    }
	&-Link {
		height: 100%;
		justify-content: flex-end;
	}

    &-Picture {
        padding-bottom: 100%;
		background-color: var(--image-background-color);
		.Image-Image {
			mix-blend-mode: multiply;
		}
    }

    &-Brand {
        display: none;
    }

    &-Name {
        text-transform: none;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    &-PriceWrapper {
        @at-root .ProductCard_siblingsHavePriceBadge & {
            min-height: 0;
        }
    }

    &-Price {
        font-size: 1.4rem;
        font-weight: var(--font-weight);
    }

    &-SpecialPrice {
        font-size: 1.6rem;
        color: var(--price-with-discount-color);
        font-weight: 500;
    }

    &-ConfigurableOptions {
        display: none;
    }

    &-badge {
        background: #15426c;
        color: #fff;
    }

    &-BottomBadges {
        line-height: 1.4;
        padding-bottom: 2px;
    }
    
    &-BottomBadge {
        display: block;
        font-size: 1.2rem;
        color: var(--body-content-color);
        //padding-bottom: 5px;

        // &:not(:first-child) {
        //     display: inline-block;
        //     margin-left: 0.6rem;
        //     padding-left: 0.6rem;

        //     @include mobile {
        //         margin-left: 0;
        //         padding-left: 0;
        //         padding-top: 5px;
        //     }

        //     &::before {
        //         content: '|';
        //         position: absolute;
        //         left: 0;
        //         top: 0;

        //         @include mobile {
        //             content: '';
        //         }
        //     }
        // }
    }

    &-VariantBadges {
        position: absolute;
        bottom: 22px;
        right: 20px;
        height: 1.5rem;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @include mobile {
            position: relative;
            bottom: 0;
            right: 0;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 1rem;
        }

    }

    &-VariantBadge {
        font-size: 1.2rem;
        color: #8d8d8d;
        height: 1.5rem;
        display: inline-block;

        &:not(:first-child) {
            display: inline-block;
            margin-left: 0.6rem;
            padding-left: 0.6rem;

            &::before {
                content: '|';
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    .News-badge {
        background: #8aa5bd;
        color: #15426c;
    }

    &-Badges {
        position: absolute;
        top: 0;
        left: 0;
    }

    &-Badge {
        color: #fff;
        min-width: 150px;
        text-align: center;
        background-color: var(--primary-base-color);
        margin-bottom: .5rem;
        padding: .3rem 1rem;
        font-size: 1.1rem;
        text-transform: uppercase;

        &.BadgeNew {
            display: none;
        }

        .ProductConfigurableAttributesPopup-Popup & {
            &.BadgeNew {
                display: block;
            }
        }

        @include mobile {
            min-width: 120px;
            font-size: 1.1rem;
        }

        &.BadgeSpecialPrice {
            padding: 0.5rem 1.2rem;
            font-size: 1.3rem;
            line-height: 2.4rem;

			@include badge-colors;
        }

        &.BadgeNew {
            background-color: rgba(22, 67, 109, .7);
        }
    }
}
