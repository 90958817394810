:root {
    --font-family: #{$font-family};
    --font-weight: 500;
    --font-letter-spacing: 0.06rem;
    --p-font-size: 1.4rem;
    --p-font-weight: 500;
    --h1-font-size: 3.2rem;
    --h2-font-size: 2.6rem;
    --h3-font-size: 2rem;
    --h4-font-size: 1.6rem;
    --h5-font-size: 1.2rem;
    --h6-font-size: 1.1rem;
    --theme-border-radius: 0;
    --theme-border-radius-button: 0;
    --header-font-weight: 500;
    --header-margin-top: 2.4rem;
    --header-margin-bottom: 1.2rem;
    --header-hero-font-size: 8rem;
    --header-line-height: 1.2;
    --narrow-content-width: 1000px;
    --body-line-height: 1.6;
    --heading-letter-spacing: .08rem;
    --wishlist-heart-hover-color: #900;
    --grey-background-color: #f5f5f5;
    --white-background-color: #fff;
    --color-product-conf-attr-popup-main: var(--terciary-base-color);
}

.scrollDisabled {
    @include desktop {
        position: relative;
        overflow-y: hidden; 
    }
}

body {
    // letter-spacing: .02rem;
    color: var(--body-content-color);
    font-family:var(--font-family);
    font-weight: var(--p-font-weight);
    line-height: var(--body-line-height);
    letter-spacing: var(--font-letter-spacing);
    background: var(--terciary-base-color);

    .scrollDisabled &,
    .hiddenNavigationTabs & {
        @include mobile {
            overflow: hidden;
        }
    }

    // allow scroll on the checkout 
    &.isCheckout {
        .hiddenNavigationTabs & {
            @include mobile {
                overflow: auto;
            }
        }
    }

}

p {
    font-size: var(--p-font-size);
    margin-bottom: 1.2rem;
    line-height: var(--body-line-height);
}

li,
dl {
    font-size: var(--p-font-size);
}

h1 {
    font-size: var(--h1-font-size);
}

h2 {
    font-size: var(--h2-font-size);
}

h3 {
    font-size: var(--h3-font-size);
}

h4 {
    font-size: var(--h4-font-size);
}

h5 {
    font-size: var(--h5-font-size);
}

h6 {
    font-size: var(--h6-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 + h2,
h1 + h3,
h1 + h4,
h2 + h3,
h2 + h4,
h3 + h4 {
    font-weight: var(--header-font-weight);
    line-height: var(--header-line-height);
    color: var(--body-heading-color);
    top: 0;
    margin-top: 0;
    margin-bottom: var(--header-margin-bottom);
    letter-spacing: var(--heading-letter-spacing);
    text-transform: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &:not(:first-child) { margin-top: var(--header-margin-top); }
}

main,
main.ProductPage {
    @include mobile {
        // margin-top: calc(var(--header-total-height) + var(--header-sub-height));
        margin-top: var(--header-total-height);

        @at-root .isHomePage & {
            margin-top: var(--header-total-height);
        }
    }
}

body {
    input, select, textarea {
        caret-color: var(--body-content-color);
    }
}

input,
select {
    font-size: 1.4rem;
}

input {
    &[type='number'] {
        font-weight: 500;
    }
}

body .cms-spacer.triple-spacer-height {
    @include mobile {
        height: var(--cms-vertical-padding-x1);
    }
}

@include mobile {
    body .Button {
        font-size: 1.2rem;
    }
}

.Image.cms-banner-background {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    top: 0;
    left: 0;

    .Image-Image {
        object-fit: cover;
    }
}

.cms-module-categorybanner-link {
    opacity: 0;
}

.MyAccountMyWishlist {
    .Field {
        #item_qty {
            &[type='number'] {
                border: 1px solid var(--cms-button-primary-border-color) !important;

                ~ button:first-of-type,
                ~ button:last-of-type {
                    height: unset;
                    width: 20px;
                }

                ~ button:first-of-type {
                    border-left-width: 0;
                }

                ~ button:last-of-type {
                    border-right-width: 0;
                }
            }
        }
    }
}
