.FeedbackBlock {
    // margin-bottom: var(--standard-margin);
    background-color: var(--primary-base-color);
    color: #fff;

    @include desktop {
        padding-left: 50px;
        padding-right: 50px;
    }

    h3 {
        color: #fff;
    }

    a {
        color: #fff;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &-Wrapper {
        max-width: 700px;
        margin: 0 auto;
        padding-top: 5rem;
        padding-bottom: 5rem;
        text-align: center;
        padding-left: 1.4rem;
        padding-right: 1.4rem;

        @include desktop {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }

    }

}