/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../hoeks-pwa/src/style/abstract/variables';
 @import '../../../../hoeks-pwa/src/style/abstract/media';
 @import '../../../../hoeks-pwa/src/style/abstract/mixins-custom';
 @import '../../../../hoeks-pwa/src/style/abstract/button';
 @import '../../../../hoeks-pwa/src/style/abstract/loader';
 @import '../../../../hoeks-pwa/src/style/abstract/icons';
 @import '../../../../hoeks-pwa/src/style/abstract/parts';
 @import '../../../../hoeks-pwa/src/style/abstract/theme-abstract';
 
:root {
    @include desktop {
        --header-menu-height: 40px; 
        --header-total-height: calc( var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + var(--header-menu-height) + env(safe-area-inset-top) );
    }
}

body { // we should find a better way for this but it has to be on body not break other styles 
    @include before-desktop {
        --header-height: 100px;
    }
}

.Header {
    // adjust header (navigation tabs at the top)

    &-MyAccountTitle {
        display: none;
    }

    &-MinicartTitle {
        display: none;
    }

    &_name_default {
        @include before-desktop {    
            height: calc(var(--header-height) / 2);
        }
        .Header-Nav {
            @include before-desktop {  
                display: none;
            }
        }
    }

    // wrapper 
    &-Wrapper {
        margin-bottom: calc(var(--header-total-height) + 9px);
        @include before-desktop {  
            margin-bottom: var(--header-height);
        } 
        &_name_default {
            @include before-desktop {  
                margin-bottom: calc(var(--header-height) / 2);
            }
        }
    }

    // icons 
    &-Button {
        // account icon for desktop and mobile 
        &_type_account {
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
            background-size: contain;
            width: 30px;
            height: 30px;
            background-image: url(icons/icon-account.svg);
        }
        // mini cart icon for mobile 
        &_type_minicart {
            &:before {
                @include before-desktop {
                    display: none;
                }
            }
            &:after {
                @include before-desktop {
                    display: none;
                }
            }
            @include before-desktop {
                background-size: contain;
                width: 30px;
                height: 30px;
                background-image: url(icons/icon-cart.svg);
            }

            @include desktop {
                padding-top: 0;
                padding-bottom: 0;
                height: 30px;
            }

        }

        // menu icon for mobile 
        &_type_menu {
            @include before-desktop {  
                background-size: contain;
                width: 30px;
                height: 30px;
            }
            &:before {
                @include before-desktop {  
                    display: none;
                }
            }
            &:after {
                @include before-desktop {  
                    display: none;
                }
            }
            @include before-desktop {  
                background-image: url(icons/icon-menu.svg);
            }

            &.NavigationTabs-Icon_isActive  { // active menu 
                @include before-desktop {  
                    background-image: url(icons/icon-close.svg);
                    transform: rotate(45deg);
                }
            }
        }
    }

    // mini cart icon for desktop 
    &-MinicartIcon {
        &:before {
            @include desktop {
                display: none;
            }
        }
        &:after {
            @include desktop {
                display: none;
            }
        }
        @include desktop {
            background-size: contain;
            width: 30px;
            height: 30px;
            background-image: url(icons/icon-cart.svg);
        }
    }

    // cart items count for dekstop 
    &-MinicartItemCount {
        @include desktop {
            top: 0;
        }
    }

    // search icon 
    .SearchField {
        &-Wrapper {
            @include desktop {
                margin-right: 0;
            }
        }

        &-SearchIcon {
            @include desktop {
                background-size: contain;
                width: 30px;
                height: 30px;
                background-image: url(icons/icon-search.svg);
            }
            &:before {
                @include desktop {
                    display: none;
                }
            }

        }

        &-CloseIcon {
            width: 30px;
            height: 30px;
            &:after {
                height: 30px;
            }
            &:before {
                height: 30px;
            }
        }

        &-SearchWrapper {
            &_isVisible {
                .SearchField-SearchIcon {
                    @include desktop {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

    }

    &-LogoWrapper {
        @include desktop {
            right: auto;
            left: 1.2rem;
        }
    }

    &-MinicartButtonWrapper {
        @include desktop {
            height: 30px;
        }
    }

} // end header 





