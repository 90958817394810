/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.NewsletterForm {
    .myForm td, input, select, textarea, checkbox {
        font-family: tahoma;
        font-size: 12px;
    }
    
    .required {
        color: red;
    }
    
    input.fb {
        background-image: url('https://app.marketingplatform.com/admin/images/facebookautofill.jpg');
        width: 179px;
        height: 37px;
        border-radius: 5px;
        cursor: pointer;
    }

    .error {
        border: red solid 2px;
    }
}