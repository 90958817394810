// General style fixes
.CmsPage {

    @include before-desktop {
        margin-bottom: var(--bottom-mobile-navigation);
    }

    // .CmsPage-Content {
    //     .Image {
    //         padding: 0;
    //         height: auto;
    //         display: block;

    //         .Image-Image {
    //             position: relative;
    //             height: auto !important;
    //             object-fit: unset;
    //             display: block;
    //         }

    //         // &:not(.width-control) {
    //         //     width: 100% !important;
    //         // }
    //     }
    // }
}

// 12 Column Layout
.cms-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: var(--cms-full-width);
    max-width: var(--cms-full-max-width);
    margin-left: auto;
    margin-right: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    overflow-y: hidden;

    > .cms-section-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: var(--content-wrapper-width);
        width: 100%;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        margin-left: auto;
        margin-right: auto;

        &.full {
            width: var(--cms-full-width);
            max-width: var(--cms-max-width);
        }
    }

    &.cms-vertical-section-padding {
        padding-top: var(--cms-vertical-padding-x1);
        padding-bottom: var(--cms-vertical-padding-x1);
    }

    &.cms-double-vertical-section-padding {
        padding-top: var(--cms-vertical-padding-x2);
        padding-bottom: var(--cms-vertical-padding-x2);
    }

    &.cms-triple-vertical-section-padding {
        padding-top: var(--cms-vertical-padding-x3);
        padding-bottom: var(--cms-vertical-padding-x3);
    }

    &.cms-margin-top {
        margin-top: var(--cms-spacing-x1);
    }

    &.cms-margin-top-2 {
        margin-top: var(--cms-spacing-x2);
    }

    &.cms-margin-top-3 {
        margin-top: var(--cms-spacing-x3);
    }

    &.cms-margin-bottom {
        margin-bottom: var(--cms-spacing-x1);
    }

    &.cms-margin-bottom-2 {
        margin-bottom: var(--cms-spacing-x2);
    }

    &.cms-margin-bottom-3 {
        margin-bottom: var(--cms-spacing-x3);
    }

    

    &.vertical-center {
        align-items: center;
    }

    &.vertical-bottom {
        align-items: flex-end;
    }
}

.cms-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    width: calc(100% + (var(--cms-spacing-x1) * 2));
    margin: 0 calc(var(--cms-spacing-x1) * -1);

    &::after {
        content: '';
        display: table;
        clear: both;
    }

    > .cms-col {
        padding: 0 var(--cms-spacing-x1);
    }

    &.vertical-center {
        align-items: center;
    }

    &.has-vertical-col-spacing {
        margin-bottom: calc((var(--cms-spacing-x1) * -1) * 2);

        > .cms-col {
            margin-bottom: calc(var(--cms-spacing-x1) * 2);
        }
    }

    &.double-col-spacing {
        width: calc(100% + (var(--cms-spacing-x2) * 2));
        margin: 0 calc(var(--cms-spacing-x2) * -1);

        > .cms-col {
            padding: 0 var(--cms-spacing-x2);
        }

        &.has-vertical-col-spacing {
            margin-bottom: calc((var(--cms-spacing-x2) * -1) * 2);

            > .cms-col {
                margin-bottom: calc(var(--cms-spacing-x2) * 2);
            }
        }
    }

    &.triple-col-spacing {
        width: calc(100% + (var(--cms-spacing-x3) * 2));
        margin: 0 calc(var(--cms-spacing-x3) * -1);

        > .cms-col {
            padding: 0 var(--cms-spacing-x3);
        }

        &.has-vertical-col-spacing {
            margin-bottom: calc((var(--cms-spacing-x3) * -1) * 2);

            > .cms-col {
                margin-bottom: calc(var(--cms-spacing-x3) * 2);
            }
        }
    }

    &.equal-height-cols {
        > .cms-col {
            > .cms-col-inner {
                height: 100%;
            }
        }
    }

    &.center-cols {
        justify-content: center;
    }

    &.right-cols {
        justify-content: flex-end;
    }

    &.reverse-on-mobile {
        @include before-desktop {
            flex-direction: column-reverse;
        }
    }
}

.cms-col.cms-col-1 { width: ((100% / 12) * 1); }
.cms-col.cms-col-2 { width: ((100% / 12) * 2); }
.cms-col.cms-col-3 { width: ((100% / 12) * 3); }
.cms-col.cms-col-4 { width: ((100% / 12) * 4); }
.cms-col.cms-col-5 { width: ((100% / 12) * 5); }
.cms-col.cms-col-6 { width: ((100% / 12) * 6); }
.cms-col.cms-col-7 { width: ((100% / 12) * 7); }
.cms-col.cms-col-8 { width: ((100% / 12) * 8); }
.cms-col.cms-col-9 { width: ((100% / 12) * 9); }
.cms-col.cms-col-10 { width: ((100% / 12) * 10); }
.cms-col.cms-col-11 { width: ((100% / 12) * 11); }
.cms-col.cms-col-12 { width: ((100% / 12) * 12); }

@include desktop {
    .cms-col.cms-col-1-lg { width: ((100% / 12) * 1); }
    .cms-col.cms-col-2-lg { width: ((100% / 12) * 2); }
    .cms-col.cms-col-3-lg { width: ((100% / 12) * 3); }
    .cms-col.cms-col-4-lg { width: ((100% / 12) * 4); }
    .cms-col.cms-col-5-lg { width: ((100% / 12) * 5); }
    .cms-col.cms-col-6-lg { width: ((100% / 12) * 6); }
    .cms-col.cms-col-7-lg { width: ((100% / 12) * 7); }
    .cms-col.cms-col-8-lg { width: ((100% / 12) * 8); }
    .cms-col.cms-col-9-lg { width: ((100% / 12) * 9); }
    .cms-col.cms-col-10-lg { width: ((100% / 12) * 10); }
    .cms-col.cms-col-11-lg { width: ((100% / 12) * 11); }
    .cms-col.cms-col-12-lg { width: ((100% / 12) * 12); }

    .hidden-on-lg {
        display: none;
    }
}

@include before-desktop {
    .cms-col.cms-col-1-bd { width: ((100% / 12) * 1); }
    .cms-col.cms-col-2-bd { width: ((100% / 12) * 2); }
    .cms-col.cms-col-3-bd { width: ((100% / 12) * 3); }
    .cms-col.cms-col-4-bd { width: ((100% / 12) * 4); }
    .cms-col.cms-col-5-bd { width: ((100% / 12) * 5); }
    .cms-col.cms-col-6-bd { width: ((100% / 12) * 6); }
    .cms-col.cms-col-7-bd { width: ((100% / 12) * 7); }
    .cms-col.cms-col-8-bd { width: ((100% / 12) * 8); }
    .cms-col.cms-col-9-bd { width: ((100% / 12) * 9); }
    .cms-col.cms-col-10-bd { width: ((100% / 12) * 10); }
    .cms-col.cms-col-11-bd { width: ((100% / 12) * 11); }
    .cms-col.cms-col-12-bd { width: ((100% / 12) * 12); }

    .hidden-on-bd {
        display: none;
    }
}

@include mobile {
    .cms-col.cms-col-1-sm { width: ((100% / 12) * 1); }
    .cms-col.cms-col-2-sm { width: ((100% / 12) * 2); }
    .cms-col.cms-col-3-sm { width: ((100% / 12) * 3); }
    .cms-col.cms-col-4-sm { width: ((100% / 12) * 4); }
    .cms-col.cms-col-5-sm { width: ((100% / 12) * 5); }
    .cms-col.cms-col-6-sm { width: ((100% / 12) * 6); }
    .cms-col.cms-col-7-sm { width: ((100% / 12) * 7); }
    .cms-col.cms-col-8-sm { width: ((100% / 12) * 8); }
    .cms-col.cms-col-9-sm { width: ((100% / 12) * 9); }
    .cms-col.cms-col-10-sm { width: ((100% / 12) * 10); }
    .cms-col.cms-col-11-sm { width: ((100% / 12) * 11); }
    .cms-col.cms-col-12-sm { width: ((100% / 12) * 12); }

    .hidden-on-sm {
        display: none;
    }
}

.cms-col-inner {
    padding: var(--cms-col-content-x1);
    background-color: transparent;

    &.cms-col-inner-padding-2 {
        padding: var(--cms-col-content-x2);

        @include before-desktop {
            padding: calc(var(--cms-col-content-x2) / 2);
        }
    }

    &.cms-col-inner-padding-3 {
        padding: var(--cms-col-content-x3);

        @include before-desktop {
            padding: calc(var(--cms-col-content-x3) / 3);
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &.cms-col-inner-no-padding {
        padding: 0;
    }
}
