.FeaturedCategories {
    margin-bottom: 2.8rem;

	@include desktop {
		margin-bottom: 1.2rem;
	}

    .ProductPage-FeaturedCategories & {
        margin-bottom: 0;
    }


    .CmsPage .CmsPage-Content &,
    .CategoryPageBottom &,
    .ProductPage-FeaturedCategories & {
        .Image {
            position: relative;
            width: 100%;
            height: #{'calc((100vw - 1.2rem) / 1.51)'}!important;
            padding-bottom: 0;
            order: 2;

            @include desktop {
                height: 100%!important;
                order: 1;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0);
            }
    
            .Image-Image {
                width: 100%!important;
                height: 100%!important;
                object-fit: cover!important;
                @include desktop {
                    transition: ease-in all .2s;
                }
            }
    
        }
    }

    &-Wrapper {
        display: grid;
        gap: 1.6rem;
        grid-template-columns: repeat(2,1fr);

        @include desktop {
            //grid-template-rows: repeat(1,450px);
            grid-template-columns: repeat(4,1fr);
        }
        @include tablet {
            grid-template-columns: repeat(2,1fr);
        }
    }

    &-Element {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        
        @include tablet {
            height: 350px;
        }

        @include desktop {
            height: calc(100% - 3.6rem);
			aspect-ratio: 0.75;
        }

        &:hover {
            .Image-Image {
                @include desktop {
                    transform: scale(1.05);
                    transition: ease-in all .2s;
                }
            }
        }

    }

    &-Content {
        padding: 2.4rem 0 1.2rem;
        order: 1;
        @include desktop {
            padding: 1.2rem 0 0;
            order: 2;
        }
        p {
            font-size: 1.2rem;

            @include desktop {
                font-size: 1.3rem;
            }

        }
        h3 {
            font-size: 2rem!important;
            margin-bottom: 0;
            font-weight: 500;

            // @include desktop {
            //     font-size: 2rem!important;
            // }
        }
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }

}