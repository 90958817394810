/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.ProductConfigurableAttributeDropdown {
    &-RemoveOption {
        &.Button {
            background-color: transparent;
            border: none;
            font-size: 0;
            padding: 0;
            position: absolute;
            top: 0;
            left: 100%;
            height: calc(100% + 1.2rem + 3px);
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            margin-top: -.5rem;
            width: 40px;

            @include mobile {
                // left: unset;
                // right: 0;
                margin: 0;
                height: calc(28px + 1.4rem);
            }

            &:hover {
                border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 20px;
                width: 1px;
                background: rgba(0, 0, 0, .1);
                left: 0;
            }

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-image: url(icons/remove.svg);
                background-size: 23px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    // @at-root .ProductPage .ProductConfigurableAttributes-ExpandableContentContent & {
    //     max-width: calc(100% - 40px);
    // }
}
