@mixin after-phone {
    @include desktop {
        @content;
    }
    @include tablet {
        @content;
    }
}

@mixin wide-wrapper {
    @include desktop {
        padding-left: 50px;
        padding-right: 50px;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin mobile-padding {
    @include mobile {
        padding-left: 1.4rem;
        padding-right: 1.4rem;
    }
}

@mixin image-reset {
    .Image {
        &:not(.Logo) {
            padding: 0;
            height: auto;
            display: block;
            width: auto !important;

            .Image-Image {
                position: relative;
                height: auto !important;
                object-fit: unset;
                display: block;
                width: auto !important;
            }
        }
    }
}

@mixin text-arrow {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    transform: translateY(-2px) rotate(313deg);
    pointer-events: none;
    position: absolute;
    right: -10px;
    bottom: 0;
    top: 4px;
    margin: auto;
    border-style: solid;
    border-color: #000;
    border-width: 0 1px 1px 0;
}

@mixin cms-btn {
    text-decoration: none;
    display: inline-block;
    border-radius: 0;
    font-weight: var(--button-font-weight);
    font-size: var(--button-font-size);
    line-height: normal;
    text-transform: uppercase;
    padding: 1rem 2rem;
    color: var(--button-color);
    background-color: var(--button-background);
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    transition-timing-function: ease-out;
    transition-duration: .25s;
    cursor: pointer;
    border-width: var(--button-border-width);
    border-style: solid;
    border-color: var(--button-border);
    border-radius: var(--theme-border-radius-button);
    text-transform: none;
    letter-spacing: var(--heading-letter-spacing);
    margin-right: 2rem;

    &:hover {
        border-color: var(--button-hover-border);
        background-color: var(--button-hover-background);
        color: var(--button-hover-color);
    }

    &:last-child {
        margin-right: 0;
    }
}

@mixin badge-colors {
	&[data-content^="IC Pris" i],
	&[data-content="Blue price" i] {
		color:  var(--badge-special-color);
		background-color: var(--badge-background-color);
	}

	&[data-content="Tilbud" i] {
		color:  var(--badge-offer-color);
		background-color: var(--badge-background-color);
	}
}