/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/mixins-custom';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
.LocalWishlistPage {
    &-Container {
        max-width: var(--content-wrapper-width);
        margin-left: auto;
        margin-right: auto;
        padding: 4.8rem 1.2rem;
    }

    &-Empty {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4.8rem 0;
        font-size: var(--h3-font-size);
        color: var(--header-font-color);
        font-weight: var(--header-font-weight);
        text-transform: uppercase;
    }
}
