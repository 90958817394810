/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
:root {
    @include after-mobile {
        --header-top-menu-height: 45px;
        --header-nav-height: 100px;
    }
}

.Header {
    border-bottom: 0;
    transition: all ease-in .2s;
    background-color: var(--terciary-base-color);

    @include desktop {
        box-shadow: transparent 0 13px 27px -5px, transparent 0 8px 16px -8px;
    }

    .Header-Wrapper_scrolled & {
        z-index: 15;
        @include desktop {
            box-shadow: rgba(177, 177, 179, .25) 0 13px 27px -5px, rgba(225, 221, 221, .3) 0 8px 16px -8px;
        }

		// @include mobile {
		// 	box-shadow: 0 0 11px 0 rgba(0, 0, 0, .1);
		// }
    }

    .isCheckout & {
        position: relative;
        border-bottom: 1px solid #e1e1e1;
        box-shadow: none;
    }

    &-Wrapper {
        .isHomePage & {
            @include desktop {
                margin-bottom: calc(var(--header-total-height) + 40px);
            }
        }

        @include mobile {
            margin-bottom: 50px;
        }

        .isCheckout & {
            margin-bottom: 0;
        }
    }

    &-TopMenu {
        display: block;
        padding: var(--full-width-wrapper-padding);
        max-width: 100%;
        transition: all ease-in .2s;
        opacity: 1;
        

        .Header-Wrapper_scrolled & {
            height: 0;
            // opacity: 0;
            visibility: hidden;
            overflow: hidden;
            opacity: 0;
        }

        .isCheckout & {
            display: none;
        }
    }

    &-Nav {
        max-width: none;
        margin-right: 50px;
        margin-left: 50px;
        padding-left: 0;
        padding-right: 0;
        transition: all ease-in .2s;

        &_item_id_1 {
            @include mobile {
                display: none!important;
            }

            & + div {
                @include mobile {
                    height: calc(100% - 100px); 
                    padding-top: 1.2rem!important;
                }
               & + div {
                @include mobile {
                    bottom: 100px;
                }
               }
            }

        }

        // @include mobile {
        //     display: none;
        // }

        @include mobile {
            height: 0 !important;
            margin: 0 1.2rem;
        }

        .Header-Wrapper_scrolled & {
            height: 70px;
        }

        .isCheckout & {
            height: var(--header-nav-height);
        }
    }

    &-LogoWrapper {
        left: .6rem;
        width: 300px;
        @include largescreens {
            width: 400px;
        }
    }

    &-Button {
        &_type_minicart {
            padding-right: 0;
            order: 2;
            margin-left: 1.2rem;

            @include mobile {
                background-repeat: no-repeat;
                background-position: right;
                background-size: 24px 24px;
            }
        }

        &_type_wishlist {
            opacity: 1;
            pointer-events: all;
            order: 1;

            @at-root .Header-Nav & {
                @include mobile {
                    display: none;
                }
            }
        }

        &_type_close {
            @include mobile {
                position: absolute;
                top: 1rem;
                right: 0;
                background-color: transparent;
            }
        }

        &_type_clear {
            @include mobile {
                position: absolute;
                top: 1rem;
                right: unset;
                left: 0;
                background-color: transparent;
            }
        }
    }

    &-Button_type_wishlist,
    &-Button_type_minicart,
    &-Button_type_search {
        @at-root body & { // Needs to be here to overwrite base theme
            height: 30px;
            width: 30px;
            padding: 0;

            button,
            a {
                height: 100%;
                width: 100%;
                margin: 0;

                > span[class*='Icon'] {
                    background-position: center;
                    height: 100%;
                    width: 100%;
                    display: block;
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
					@include desktop {
						background-size: 30px 30px;
					}
                }
            }
        }
    }

	&-Button_type_menu {
		@at-root body & { // Needs to be here to overwrite base theme
            height: 30px;
            width: 30px;
			background-size: 24px 24px;
            background-repeat: no-repeat;
			background-position: center;
			@include desktop {
				background-size: 30px 30px;
			}
		}
	}

    &-Button_type_search {
        @at-root body & {
            opacity: 1;
            margin-right: 0.4rem;

            .HelloRetailSearch-Input {
                padding: 0;
                pointer-events: all;
                background-color: var(--terciary-base-color);
            }

            > span[class*='Icon'] {
                background-position: center;
                left: 0;
                height: 100%;
                width: 100%;
                display: block;
                background-size: 20px 20px;
                background-repeat: no-repeat;
                background-position: center;
                pointer-events: none;
            }
        }
    }

    &-WishlistIcon {
        background-image: url('icons/Header-heart.svg');
    }

    &-MinicartIcon {
        background-image: url('icons/Header-cart.svg');
    }

    &-MinicartButtonWrapper {
        padding-right: 0;
        margin-left: .6rem;
    }

    .ClerkSearch .Image:not(.Logo) .Image-Image {
        position: absolute;
        height: 100% !important;
        width: 100% !important;
        object-fit: contain;
    }
}
