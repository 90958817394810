/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/mixins-custom';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
:root {
    --wishlist-heart-size: 30px;
}

.AddToWishlistButton {
    &-Button {
        &.Button {
            background-color: transparent !important;
            border: none !important;
        }
    }

    &-Heart {
        background-image: url(icons/heart.svg);
        background-color: transparent;
        transform: rotateZ(0deg);

        &::before,
        &::after {
            background: transparent !important;
        }

        @at-root .AddToWishlistButton-Button_isInWishlist & {
            background-image: url(icons/heart-full.svg);
        }
    }

    &:hover {
        .AddToWishlistButton-Heart {
            background-image: url(icons/heart-full.svg);
        }
    }
}
