/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../src/style/abstract/variables';
 @import '../../../../../src/style/abstract/media';
 @import '../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../src/style/abstract/button';
 @import '../../../../../src/style/abstract/loader';
 @import '../../../../../src/style/abstract/icons';
 @import '../../../../../src/style/abstract/parts';
 @import '../../../../../src/style/abstract/theme-abstract';
 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-categories-border: 1px solid lightgray;
    --blog-category-chewron-border: 1px solid gray;
    --blog-category-link-font-size: 14px;
    --blog-category-chewron-size: 10px;
}

.BlogCategories {
    &-Category {
        display: block;
        border-top: var(--blog-categories-border);
        text-align: center;

        &:last-child {
            border-bottom: var(--blog-categories-border);
        }

        &::after {
            position: absolute;
            top: 40%;
            right: 15px;
            display: inline-block;
            width: var(--blog-category-chewron-size);
            height: var(--blog-category-chewron-size);
            content: '';
            transform: rotate(45deg);
            border-top: var(--blog-category-chewron-border);
            border-right: var(--blog-category-chewron-border);
        }

        @include after-mobile {
            display: flex;
        }
    }

    &-Title {
        text-align: center;
        text-transform: uppercase;

        @include after-mobile {
            text-align: left;
        }
    }
}
