.cms-button {
    width: auto;
    margin: auto;
    text-decoration: none;
    display: inline-block;
    text-transform: none;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    transition-timing-function: ease-out;
    transition-duration: .25s;
    cursor: pointer;
    border-radius: var(--cms-button-border-radius);
    font-weight: var(--cms-button-font-weight);
    font-size: var(--cms-button-font-size);
    line-height: var(--cms-button-line-height);
    padding: var(--cms-button-padding);
    border-width: var(--cms-button-border-width);
    border-style: var(--cms-button-border-style);

    @include mobile {
        font-size: var(--cms-button-font-size-mobile);
    }

    // Button Primary
    color: var(--cms-button-primary-color);
    background-color: var(--cms-button-primary-background-color);
    border-color: var(--cms-button-primary-border-color);

    &:hover,
    &:focus {
        text-decoration: none;

        @include desktop {
            // Button - Primary Hover
            color: var(--cms-button-primary-hover-color);
            background-color: var(--cms-button-primary-hover-background-color);
            border-color: var(--cms-button-primary-hover-border-color);
        }
    }

    &.cms-button-secondary {
        // Button - Secondary
        color: var(--cms-button-secondary-color);
        background-color: var(--cms-button-secondary-background-color);
        border-color: var(--cms-button-secondary-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - Secondary Hover
                color: var(--button-color);
                background-color: var(--cms-button-primary-hover-background-color);
                border-color: var(--cms-button-secondary-hover-border-color);
            }
        }
    }

    &.cms-button-light-hollow {
        // Button - Secondary
        color: var(--cms-button-light-hollow-color);
        background-color: var(--cms-button-light-hollow-background-color);
        border-color: var(--cms-button-light-hollow-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - Secondary Hover
                color: var(--cms-button-light-hollow-hover-color);
                background-color: var(--cms-button-light-hollow-hover-background-color);
                border-color: var(--cms-button-light-hollow-hover-border-color);
            }
        }
    }

    + .cms-button {
        margin-left: var(--cms-button-spacing);

        // Don't really know how to solve this
        // @include mobile {
        //     margin-top: var(--cms-button-spacing);
        // }
    }
}
