/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Menu {
    &-MenuWrapper {

        @include mobile {
            display: flex;
            flex-direction: column;
            padding: 2.4rem;
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
        }

        .Header-Nav {
            display: none;
        }
    }

    &-Item {
        &:last-child {
            padding-right: 0;
        }

        @include desktop {
            padding: 0;
        }

        @include mobile {
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    &-SubLevelDesktop {
        margin-top: .6rem;
    }

    &-ItemFigure {
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--body-heading-color);

        @at-root .Menu-SubLevelDesktop & {
            font-weight: var(--font-weight);
            color: var(--body-content-color);
        }

        .Menu-SubItemWrapper & {
            font-size: 1.7rem;
        }

        @at-root .view-all & {
            @at-root .Menu-SubLevelDesktop & {
                font-weight: 600;
            }
        }

        @at-root .Menu-SubItemWrapper & {
            @include mobile {
                margin: 10px 0;
            }
        }

        &_type {
            &_main {
                @include mobile {
                    height: auto;
                    padding-left: 0;
                    background: none;
                    grid-template-columns: 1fr;
                }
            }

            &_subcategory {
                @include mobile {
                    margin: 10px 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &-ItemCaption {
        &_type {
            &_main {
                font-size: 1.5rem;
                font-weight: 500;
                color: var(--body-heading-color);
                letter-spacing: .06rem;
                text-transform: none;

                @include mobile {
                    font-size: 1.4rem;
                }

                @at-root .Menu-Link_isHovered & {
                    color: var(--body-heading-color) !important;
                }
            }

            &_subcategory {
                @include mobile {
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: var(--body-heading-color);
                    letter-spacing: .06rem;
                    padding: 0;
                    text-transform: uppercase;
                    line-height: 1.6rem;
                }
            }
        }

        @at-root .Menu-SubItemWrapper & {
            @include mobile {
                font-size: 1.4rem;
                font-weight: 600;
                color: var(--body-heading-color);
                letter-spacing: .06rem;
                padding: 0;
                text-transform: uppercase;
                line-height: 1.6rem;
            }
        }

        @at-root .isFeatured & {
            font-weight: 600;
        }
    }

    &-Link {
        transition: all ease .1s;

        @at-root .Menu-SubLevelDesktop & {
            margin-top: 5px;

            @media screen and (min-width: 1025px) and (max-width: 1439px) {
                margin-top: 3px;
            }

            .Menu-ItemFigure {
                font-size: 1.5rem;
                font-weight: 500;

                @media screen and (min-width: 1025px) and (max-width: 1439px) {
                    font-size: 1.45rem;
                }
            }
        }

        @include desktop {
            padding: 0 2.4rem;
        }

        @include mobile {
            padding: 0;
        }

        &::before {
            .isHomePage &,
            .isBlogOverview &,
            .Header-Wrapper_name_posts-details &,
            .isCmsPage & {
                @include desktop {
                    background-color: var(--terciary-base-color);
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 0;
                    display: block;
                    position: absolute;
                    transition: none;
                }
            }
        }

        &_isHovered {
            @include desktop {
                color: var(--body-heading-color);

                &::before {
                    .isHomePage &,
                    .isBlogOverview &,
                    .Header-Wrapper_name_posts-details &,
                    .isCmsPage & {
                        height: var(--header-menu-height);
                        transition: all ease .1s;
                    }
                }
            }
        }

        &.isDeactivated {
            @include desktop {
                pointer-events: none;
            }
        }
    }

    &-ItemList {
        @include mobile {
            padding-bottom: 20px;
        }
        &_type {
            &_main {
                @include mobile {
                    width: 100%;
                    height: 100%;
                    display: block;
                    overflow-y: scroll;
                }
            }

            &_subcategory {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                background-color: var(--primary-base-color);

                @include mobile {
                    padding: 2.4rem;
                }

                @include desktop {
                    margin-bottom: -2.4rem;
                    background-color: var(--terciary-base-color);
                }

                @at-root .single-col-item & {
                    display: grid;
                    grid-template-columns: 2fr 3fr;
                    max-width: 50%;
                    margin: 0 auto;
                }
            }
        }

        &:not(.Menu-ItemList_type_main) {
            @include mobile {
                margin-top: 0 !important;
                grid-template-columns: 1fr;
            }
        }

        > div {
            > .Menu-ItemFigure {
                margin: 10px 0;
            }

            > .Menu-SubMenu {
                .Menu-SubItemWrapper {
                    .Menu-ItemFigure {
                        margin: 10px 0;
                    }

                    &:first-child {
                        .Menu-ItemFigure {
                            margin-top: 0;
                        }
                    }
                }
            }

            &:first-child {
                .Menu-ItemFigure {
                    margin-top: 0;
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        -webkit-clip-path: inset(0 0 100% 0);
        clip-path: inset(0 0 100% 0);
        animation-name: fade-in;
        animation-iteration-count: 1;
        animation-duration: .3s;
        animation-fill-mode: forwards;
		.Menu-SubItemWrapper {
			& > .Menu-Link {
				text-transform: none;
			}
		} 
    }

    &-SubCategoriesWrapperInner {
        border-bottom: 1px solid rgba(0,0,0,0.05)!important;
        background-color: var(--terciary-base-color);
    }

    &-SubCategories {
        padding: 2.4rem;
    }

    &-Overlay {
        top: 0;
    }

    &-SubItemWrapper {
        @include desktop {
            margin-bottom: 2.4rem;
        }

        @at-root .single-col-item & {
            flex: 0 1;
            margin: 0;
            margin-bottom: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            grid-column: 1 / 2;

            &:last-child {
                grid-column: 2/3;
                grid-row: 1/999;
            }
        }

        .ic-menu-item-hidden {
            @include desktop {
                display: none;
            }
            & + .Menu-SubLevelDesktop {
                @include desktop {
                    padding-left: 3rem;
                    border-left: 1px solid var(--terciary-base-color-opacity);
                }
            }
        }

        .span-3 {
            & + .Menu-SubLevelDesktop {
                .Menu-ItemList  {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }
        }

        .moebel-item & {
            &:last-child {
                @include desktop {
                    grid-column: 5 / 7;
                }
            }
        }

        .belysning-item & {
            &:last-child {
                @include desktop {
                    grid-column: 3 / 7;
                } 
            }
        }

        .layout-col-3 & {
            &:first-child {
                @include desktop {
                    grid-column: 1 / 4;
                } 
            }
            &:last-child {
                @include desktop {
                    grid-column: 5 / 7;
                } 
            }
        }

        .ic-menu-item-image {
            .Menu-ItemCaption {
                display: none;
            }
            .Image {
                width: 100%!important;
                height: 250px!important;
                max-width: 420px!important;
                margin-left: auto!important;

                .Image-Image {
                    width: 100%!important;
                    height: 100%!important;
                    object-fit: cover!important;
                }

            }
        }

        .ic-menu-item-btn {
            @include desktop {
                @include cms-btn;
                .Menu-ItemCaption {
                    color: #fff;
                    font-size: 1.4rem;
                    font-weight: 500;
                }

                &:hover {
                    .Menu-ItemCaption {
                        color: #000;
                    } 
                }
            }
        }

    }

    &-Promotion {
        display: none;
    }

    &-MainCategories {
        @include mobile {
            flex-direction: column-reverse;
            // flex: 1;
            align-items: flex-start;
            justify-content: flex-end;
            padding-bottom: 80px;
            margin-top: 3rem;
            padding-bottom: 120px;
            // height: calc(100% - 200px);
            z-index: 1;
        }
    }

    &-SubMenu {
        @include mobile {
            background: #fff;
            padding-top: 0;
            bottom: unset;
            top: var(--header-total-height);
            height: calc(100% - var(--header-total-height));

            .Menu-SubMenu {
                top: 0;
            }
        }
    }
}

@keyframes fade-in {
    from {
        -webkit-clip-path: inset(0 0 100% 0);
        clip-path: inset(0 0 100% 0);
    }

    to {
        -webkit-clip-path: inset(0);
        clip-path: inset(0);
    }
}
