/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --input-type-number-width: 80px;
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderanimation 2s linear infinite;
    --primary-divider-color: #dfdcd0;
    --primary-error-color: #dc6d6d;
    --secondary-error-color: #fff;
    --primary-success-color: #8da051;
    --secondary-success-color: #fff;
    --primary-info-color: #beb9ab;
    --secondary-info-color: #fff;

    // Theme text color
    --body-heading-color: #000000;
    --body-heading-color-light: #eaeaea;
    --body-content-color: #000000;
    --body-content-color-light: #f0f0f0;

    // Theme specific colors
    --grey-background-main: #{$default-secondary-base-color};
    --grey-background-light: #f9f9f9;
    --grey-background-dark: #{$default-secondary-dark-color};
    --theme-dark-blue: #0f223d;
    --popup-background: rgba(0, 0, 0, .5);
    --white: $white;
    --black: $black;
    --theme-border-color: rgba(0, 0, 0, .07);
    --theme-color-1: #faf7f4;
    --theme-color-2: #d3cdc4;
    --theme-color-3: #d5d0cd;
    --theme-color-4: #474341;
    --theme-color-5: #9a998c;
    --blog-categories-border: 1px solid rgba(0, 0, 0, .07);
    --badge-background-color: #918C86;
    --badge-news-background-color: #{$default-primary-base-color};
    --badge-special-color: #{$default-terciary-base-color};
    --badge-offer-color: #FCCF00;
    --badge-news-color: #{$default-terciary-base-color};
    --theme-table-secondary-color: #eeeae1;
    --input-background-color: #{$white};
	--image-background-color: #fff; // #e9e9e7

    // Declare imported primary colors to change the theme's colors
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --primary-dark-border-color: #662c1e;
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
    --terciary-base-color: var(--imported_terciary_dark_color, #{$default-terciary-base-color});
    --terciary-dark-color: var(--imported_terciary_dark_color, #{$default-terciary-dark-color});
    --terciary-light-color: var(--imported_terciary_light_color, #{$default-terciary-light-color});
    --terciary-base-color-opacity: rgba(184, 180, 171, .2);
    --terciary-base-color-high-opacity: rgba(184, 180, 171, .4);
    --terciary-title-color: #A19C91;
    --terciary-darkbg-color: #E8E6DA;
    --terciary-dark-border-color: #d4d2c7;
    --full-width-wrapper-padding: 0 50px 0 50px; 
    --search-item-title-color: #000;
    --breadcrumbs-background: var(--terciary-base-color);
}
