/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.VariantFilters {
    &-Wrapper {
        @include desktop {
            padding-right: 1.6rem;
        }
    }

    &-FilteringWrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.2rem;
        margin-bottom: 1.2rem;
        @include mobile {
            display: block;
        }
    }

    &-Expandable {
        position: relative;
        @include mobile {
            margin-bottom: 1.2rem;
        }
    }

    &-ExpandableContentButton {
        width: 100%;
        padding: .6rem .6rem .6rem 1.2rem;
        text-align: center;
        border: 1px solid var(--body-heading-color);
        height: 3.8rem;
        // transition: .3s ease-in border-bottom-color;
        @include mobile {
            transition: none;
        }
        @include desktop {
            z-index: 10;
        }
        &:hover {
            cursor: pointer;
        }
        &:after,
        &:before {
            content: none;
        }
        &.ExpandableContent-Button_isContentExpanded {
            &:after {
                content: "";
                display: block;
                width: 100%;
                left: 0;
                position: absolute;
                bottom: -3px;
                height: 4px;
                transform: none;
                margin: 0; 
                top: auto;
                right: auto;
                background-color: var(--color-product-conf-attr-popup-main);;
            }
        }
    }

    &-ExpandableContentHeading {
        color: var(--body-heading-color);
        font-size: 1.2rem;
        text-align: left;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            height: 20px;
            width: 20px;
            background-image: url(icons/chevron-down.svg);
            transform: rotate(0deg);
            transition: all ease .3s;

            @at-root .ExpandableContent-Button_isContentExpanded & {
                transform: rotate(180deg);
            }
        }
    }

    &-ExpandableContentContent {
        padding: 1.2rem;
        position: absolute;
        top: 3.8rem;
        left: 0;
        width: 100%;
        z-index: 20;
        background-color: var(--color-product-conf-attr-popup-main);
        margin-top: 0;
        border: 1px solid var(--theme-color-4);
        border-top: 0;
        box-shadow: #eaeaea 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.047) 0px 2.6px 2.5px, rgba(0, 0, 0, 0.04) 0px 5.3px 5.1px, rgba(0, 0, 0, 0.03) 0px 11px 10.6px, rgba(0, 0, 0, 0.024) 0px 30px 29px;
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: .3s ease-in all;
        max-height: 200px;
        overflow-y: auto;

        @include mobile {
            position: relative;
            transform: none;
            display: none;
            top: 0;
            opacity: 0;
        }

        &_isContentExpanded {
            opacity: 1;
            transform: scaleY(1);

            @include mobile {
                display: block;
                opacity: 1;
            }
        }
    }

    &-SelectedFilters {
        margin-bottom: -1.2rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 1.2rem;
        & > div {
            margin-bottom: 1.2rem;
        }
    }

    &-Label {
        margin-right: 1.2rem;
        white-space: nowrap;
        font-weight: bold;
        line-height: 30px;
    }

    &-SelectedFilter {
        background-color: var(--white-background-color);
        padding: 0 2.4rem;
        padding-left: .8rem;
        display: flex;
        align-items: center;
        border: 1px solid var(--primary-divider-color);
        margin-right: 1.2rem;
        min-height: 28px;
        box-shadow: 2px 5px 15px #0000000d;
        span {
            display: block;
            margin-right: .6rem;
            white-space: nowrap;
            line-height: 28px;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-SelectedFilterCloseButton {
        position: absolute;
        right: .8rem;
        font-size: 1.2rem;
        &:hover {
            cursor: pointer;
        }
    }
}