/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/mixins-custom';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
.Clerk {
    margin-top: 0;
    margin-bottom: var(--standard-margin);

    &-Wrapper {
        @include wide-wrapper;
        @include mobile-padding;

        .isHomePage & {
            max-width: none;
            padding: 0;
        }
    }

    &-Slider {
        .slick-slider {
            .slick-arrow {
                height: 40px;
                width: 40px;

                &::before {
                    height: 20px;
                    width: 20px;
                }
            }

            .ProductCard {
                padding: 0;
            }
        }
    }
}
