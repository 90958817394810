.FooterStores {
    font-size: 1.4rem;
    padding: 100px 1.4rem;
    background-color: var(--terciary-base-color);

    @include phone {
        padding: 4.8rem 1.4rem;
    }
    @include desktop {
        padding-left: 50px;
        padding-right: 50px;
    }

    h3 {
        color: var(--body-heading-color);
        text-transform: none;
        font-weight: 500;
        @include phone {
            color: var(--body-content-color);
        }

        a {
            color: var(--body-heading-color); 
            text-transform: none;
            @include phone {
                color: var(--body-content-color);
            }
        }
    }

    h4 {
        &.ttu {
            display: none;
        }
    }

    .hide-mobile {
        @include mobile {
            display: none;
        }
    }
    .newline-mobile {
        @include mobile {
            display: block;
        }
    }

    .site-footer-open-hours-title {
        text-transform: none;
        font-size: var(--h3-font-size);
        font-weight: var(--font-weight);
        text-decoration: underline;
        color: var(--body-content-color);
        padding: 0.3rem 0;
        margin: 0;
        @include tablet {
            display: none;
        }
        @include desktop {
            display: none;
        }
    }
    .site-footer-open-hours {
        @include phone {
            line-height: 1.3;
            margin-top: 2.4rem;
        }
    }

    .site-footer-heading {
        margin-bottom: 2.4rem;
    }

    .site-footer-store-details {
        margin-bottom: 3rem;
        @include phone {
            line-height: 1.3;
            margin-bottom: 0;
        }
    }

    .site-footer-shipping {
        @include phone {
            display: flex;
            justify-content: center;
        }
    }

    a {
        color: var(--body-content-color);
    }

    &-AllStores {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 4%;
            max-width: 1920px;
            margin-left: auto;
            margin-right: auto;
        }

        @include tablet {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 10%;
        }

    }

    &-Store {
        border-top: 1px solid var(--terciary-title-color);
        padding-top: 4rem;
        margin-bottom: 4rem;

        @include phone {
            text-align: center;
            font-size: var(--h3-font-size);
            border-top: none;
            padding-top: 0;
            margin-bottom: 4.8rem;
        }

        &:first-child {
            border-top: 0;
            padding-top: 0;

            @include desktop {
                border-top: 1px solid var(--terciary-title-color);
                padding-top: 4rem;
            }

            @include tablet {
                border-top: 1px solid var(--terciary-title-color);
                padding-top: 4rem;
            }

        }

        &:last-child {
            margin-bottom: 0;

            @include desktop {
                margin-bottom: 4rem;
            }

            @include tablet {
                margin-bottom: 4rem;
            }

        }

    }

    &-ExpandableMain {
        position: relative;

        & input {
            position: absolute;
            top: -2.8rem;
            right: 0;
            padding: 1.4rem !important;
            width: 100%;
            min-width: auto;
            min-height: auto;
            cursor: pointer;
            border: 0;
            background-color: transparent;

            @include after-phone {
                display: none;
            }

            &:focus {
                border: 0;
            }

            &:checked {
                & ~ .FooterStores-ExpandableMenu {
                    transition-property: opacity, visibility;
                    transition-duration: 500ms;
                    opacity: 1;
                    visibility: visible;
                    height: initial;
                }

                & ~ .FooterStores-ExpandableToggle {
                    &::before, &::after {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
            }
        }
    }

    &-ExpandableToggle { // This is an extra box for an expand indicator. Disabled.
        display: none;
        // position: absolute;
        // top: -4.8rem;
        // right: 0;
        // padding: 1.0rem !important;
        // pointer-events: none;
        // border-radius: var(--theme-border-radius-button);
        // border: 2px solid var(--primary-base-color);

        // @include after-mobile {
        //     display: none;
        // }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     width: 0.2rem;
        //     height: 1.2rem;
        //     background-color: var(--primary-base-color);
        //     top: 50%;
        //     left: 50%;
        //     transition-property: transform;
        //     transition-duration: 500ms;
        //     transform: translate(-50%, -50%);
        // }

        // &::after {
        //     content: '';
        //     position: absolute;
        //     width: 1.2rem;
        //     height: 0.2rem;
        //     background-color: var(--primary-base-color);
        //     top: 50%;
        //     left: 50%;
        //     transition-property: transform;
        //     transition-duration: 500ms;
        //     transform: translate(-50%, -50%);
        // }
    }

    &-ExpandableMenu {
        transition-property: opacity, visibility;
        transition-duration: 500ms;
        overflow: hidden;
        height: 0;
        opacity: 0;
        visibility: hidden;

        @include after-phone {
            overflow: auto;
            height: initial;
            opacity: 1;
            visibility: visible;
        }
    }
}