/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
$select-option-height: 36px !default;

.FieldSelect {
    &-PriceLabel {
        height: 25px;
        float: right;
        text-align: center;
        font-size: 1rem;
        background: #15426c;
        color: #fff;
        display: inline-block;
        line-height: 25px;
        vertical-align: top;
        padding: 0 1rem;
        margin-right: 1.2rem;
        text-transform: uppercase;
        top: 50%;
        position: absolute;
        right: 0;
        transform: translateY(-50%);
    }

    .News-badge {
        background: #8aa5bd;
        color: #15426c;
    }

    &-Option {
        font-size: small;
        padding-left: 1.2rem;
        transition: all ease .2s;
        background: var(white);
        color: #000;

        &:hover {
            background: #f5f5f5;
            padding-left: 1.2rem;
            color: #000;
        }

        &_isExpanded {
            @include mobile {
                max-height: 300px;
                border-color: var(--input-border-color);
                overflow-y: auto;
            }
        }

        &_isDisabled {
            color: rgb(151, 151, 151);
            background-color: #f1f1f1;
            cursor: default;

            &:hover {
                color: rgb(151, 151, 151);
                background-color: #f1f1f1;
            }
        }
    }

    // &-Select option:hover {
    //     background-color: red;
    //     content: "Hello World";
    // }
    
    &-Options {
        // add scrollbar to select
        .ProductActions & {
            &::-webkit-scrollbar {
                display: block;
                width: 1em;
            }

            &::-webkit-scrollbar-track {
                // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                display: block;
                background-color: #c1c1c1;
                outline: 1px solid #e0e0e0;
            }
        }
    }

}
