.FooterBottom {
    font-size: 1.4rem;
    padding: 50px 1.4rem;
    background-color: var(--terciary-light-color);
    padding-bottom: 20px;

    @include desktop {
        padding-left: 50px;
        padding-right: 50px;
    }

    h3 {
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-bottom: 2rem;

        @include desktop {
            margin-bottom: 3rem;
        }

    }

    img {
        max-width: 100%;
    }

    a {
        color: var(--body-content-color);
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 10%;
            max-width: 1920px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-PaymentMethods {
        margin-bottom: 2rem;
    }

    &-Column {
        margin-bottom: 4rem;
    }

    &-Emaerket {
        display: flex;
        align-items: center;
        p {
            margin-bottom: 0;
            margin-left: 2rem;
        }
    }

    &-Links {

        li {
            padding-left: 0;
            &:before {
                content: '';
            }
        }

        a {
            text-decoration: underline;
        }

        &_social {
            img {
                max-width: 10px;
                margin-right: 1rem;
            }
            a {
                display: flex;
                align-items: center;
            }
        }

    }

    &-LinksContainer {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 3rem;
        }
    }

    &-SupportIcons {
        @include desktop {
            display: flex;
        }

        > div {
            margin-bottom: 2rem;
            @include desktop {
                margin-bottom: 0;
            }
            &:last-child {
                margin-bottom: 0;
                @include desktop {
                    margin-left: 4rem;
                }
            }
        }

        img {
            filter: grayscale(100%);
        }

    }



}