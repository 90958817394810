/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/mixins-custom';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
.HelloRetail {
	&-Wrapper {
		max-width: 1920px;
		padding: 0 1.4rem;

		.HomePage & {
			padding: 0;
		}

		@include desktop {
			padding: 0 50px;
		}
	}

    &-Title {
        padding: 1.2rem 2.4rem 0 0;
        margin: 0;
        font-weight: 500 !important;
		border-bottom: none;
		font-size: var(--h3-font-size);
        text-transform: uppercase;
    }

    &-Slider {
		.HelloRetail & {
			margin: 0;
		}
        .slick-slider {
            margin: 0;
            .slick-list {
                margin-left: -.8rem;
                margin-right: -.8rem;
                padding: 1.2rem 0;
                overflow: hidden;
				@include desktop {
					padding: 2.4rem 0;
				}
            }

            .slick-arrow {
                top: 50% !important;
                height: 40px;
                width: 40px;

                .slick-prev {
                    left: 1.2rem !important;
                }

                .slick-next {
                    right: 1.2rem !important;
                }
            }

            .slick-slide {
                padding: 0 0.8rem;
            }

            .ProductCard {
                margin: 0;
                padding: 0;
                //box-shadow: 0 5px 6px rgba(0,0,0,0.07);
                box-shadow: none;
                border: 1px solid #f9f9f9;
				background-color: var(--image-background-color);

                &-Figure {
                    margin: 0;
                }
            }
        }
    }
}