.btn-cms {
    text-decoration: none;
    display: inline-block;
    border-radius: 0;
    font-weight: var(--button-font-weight);
    font-size: var(--button-font-size);
    line-height: normal;
    text-transform: uppercase;
    padding: 1rem 2rem;
    color: var(--button-color);
    background-color: var(--button-background);
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    transition-timing-function: ease-out;
    transition-duration: .25s;
    cursor: pointer;
    border-width: var(--button-border-width);
    border-style: solid;
    border-color: var(--button-border);
    border-radius: var(--theme-border-radius-button);
    text-transform: uppercase;
    letter-spacing: var(--heading-letter-spacing);
    margin-right: 2rem;

    &:hover {
        border-color: var(--button-hover-border);
        background-color: var(--button-hover-background);
        color: var(--button-hover-color);
    }

    &:last-child {
        margin-right: 0;
    }


}