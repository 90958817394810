/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../hoeks-pwa/src/style/abstract/variables';
 @import '../../../../hoeks-pwa/src/style/abstract/media';
 @import '../../../../hoeks-pwa/src/style/abstract/mixins-custom';
 @import '../../../../hoeks-pwa/src/style/abstract/button';
 @import '../../../../hoeks-pwa/src/style/abstract/loader';
 @import '../../../../hoeks-pwa/src/style/abstract/icons';
 @import '../../../../hoeks-pwa/src/style/abstract/parts';
 @import '../../../../hoeks-pwa/src/style/abstract/theme-abstract';
 
.Menu {
    &-MenuWrapper {
        @include desktop {
            height: var(--header-menu-height);
            min-height: auto;
            margin: 0;
            z-index: 1;
        }
    }

    &-ItemFigure {
        &_type_main {
            @include desktop {
                height: var(--header-menu-height);
            }
        }
    }

    &-ItemList {
        &_type_main {
            @include desktop {
                width: 100%;
                justify-content: center;
            }
        }
    }

    &-MainCategories {
        @include desktop {
            padding-right: 1.2rem;
        }
    }

    &-SubCategoriesWrapper {
        @include desktop {
            top: var(--header-menu-height);
        }
    }

    &-CompareLinkWrapper {
        display: none;
    }

}