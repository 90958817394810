/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.ExpandableContent {
    &-Button {
        padding-left: 0;
    }

    &-Content {
        @at-root .isCustomImageProductPage & {
            margin-top: 0 !important;
        }
    }

    .Loader {
        background: #f1f0ee;
        right: 40px;

        &-Scale {
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
        }

        &-Main {
            animation: none;
            height: auto;
            width: auto;
            left: 1.2rem;
            margin: 0;
            background: transparent;
            border: none;

            &::before,
            &::after,
            span {
                width: .4rem;
                height: .4rem;
                transform: translateY(-50%);
                border: none;
                background: #16436d;
                display: block;
                top: 50%;
                border-radius: 50%;
                animation: pulseone 1.5s ease infinite;
                margin: 0;
            }

            &::before {
                left: 0;
                animation-delay: 1s;
            }

            span {
                left: 0.8rem;
                animation-delay: .5s;
            }

            &::after {
                left: 1.6rem;
            }
        }
    }
}

@keyframes pulseone {
    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}