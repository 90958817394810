/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../style/abstract/variables';
 @import '../../../style/abstract/media';
 @import '../../../style/abstract/mixins-custom';
 @import '../../../style/abstract/button';
 @import '../../../style/abstract/loader';
 @import '../../../style/abstract/icons';
 @import '../../../style/abstract/parts';
 @import '../../../style/abstract/theme-abstract';
 
:root {
    --max-blog-width: 800px;
}

.PostsDetails {
    --total-header-height: calc(var(--header-menu-height) + var(--header-height) + 1px);

    //background-color: var(--breadcrumbs-background);

    @include desktop {
        // margin-top: calc(var(--total-header-height) * -1);
    }

    &-Header {
        // margin-top: -41px;
        height: 50vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        section {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            padding-bottom: 30px;
            padding-top: 100px;
            background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, 0) 85%);
        }

        .Image {
            padding: 0;
            height: 100%;
            width: 100%;

            img {
                object-fit: cover;
            }
        }
    }

    &-HeaderWrapper {
        max-width: var(--max-blog-width);
        padding: 0;
    }

    &-HeaderContent {
        color: var(--cms-light-font-color);

        @include mobile {
            padding: 0 2.4rem;
        }
    }

    &-Title {
        font-size: 4rem;
        color: var(--cms-light-font-color);
        text-align: left;
        margin-bottom: 10px;
        text-transform: unset;

        @include mobile {
            font-size: 2.4rem;
        }
    }

    &-HeaderMeta {
        padding-left: 5px;
        display: flex;
        flex-direction: row;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: -10px;
        //     left: 0;
        //     width: 50px;
        //     height: 1px;
        //     background-color: rgba(255, 255, 255, .5);
        // }
    }

    &-HeaderBackLinkText {
        font-size: 1.2rem;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: .06rem;
        line-height: 30px;
        vertical-align: middle;
        display: inline-block;
        margin-bottom: 20px;
        position: relative;

        &::before {
            content: '';
            background: url(icons/back.svg);
            background-size: cover;
            height: 30px;
            width: 30px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            top: -1px;
            position: relative;
        }
    }

    &-Author,
    &-Date {
        text-align: left;
        font-weight: 500;
        padding-right: 10px;
        font-size: 1.1rem;
    }

    &-ColumnWrapper {
        grid-gap: 50px;
        padding-top: 2.4rem;
        padding-bottom: 100px;
        flex-direction: column;
    }

    &-Grid {
        padding: 0;
        grid-template-columns: 1fr;
        grid-gap: var(--blog-grid-gap);
    }

    &-Description {
        flex-basis: 100%;
        width: 100%;
        padding-left: 0;
        max-width: var(--max-blog-width);
        margin: 0 auto;

        > .Image {
            margin-top: 1.2rem;
        }
    }

    &-Sidebar {
        flex-basis: 100%;
        width: 100%;
    }

    &-Content {
        margin-top: 2.4rem;

        .Image {
            margin: 0 auto;
            display: block;
            margin-top: 30px;
            margin-bottom: 30px;

            @include desktop {
                width: calc(100% + 200px);
                margin-left: -100px;
            }
        }

        .Image-Image {
            position: relative;
            object-fit: cover;
            height: auto !important;
            display: block;
        }
    }

    .event-signup-form-area {
        background: var(--terciary-base-color-opacity);
        padding: 50px;

        @include mobile {
            padding: 2.4rem;
        }
    }
}
