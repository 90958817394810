/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/mixins-custom';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
.snapengage {
    [class*='icon'] {
        height: 40px;
        width: 40px;
        display: inline-block;
        font-family: unset !important;

        &::before {
            content: '' !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: 22px;
            background-repeat: no-repeat;
        }
    }

    .icon {
        &-close {
            &::before {
                background-image: url(icons/white/close.svg);
            }
        }

        &-chat {
            &::before {
                background-image: url(icons/white/chat.svg);
            }
        }

        &-letter {
            &::before {
                background-image: url(icons/white/mail.svg);
            }
        }

        &-phone {
            &::before {
                background-image: url(icons/white/phone.svg);
            }
        }
    }

    .contact {
        &-button {
            top: unset;
            bottom: 4.8rem;
            left: 0;
            margin: 0;
            padding: 0;
            background: var(--primary-base-color);
        }

        &-popout {
            top: unset;
            bottom: 4.8rem;
            left: 0;
            padding-bottom: 0;
            box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, .1);

            .header {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background: var(--primary-base-color);

                .icon {
                    &-chat {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                button {
                    &.close {
                        padding: 0;
                    }
                }

                a {
                    color: var(--body-font-color);
                    text-decoration: none !important;
                }
            }

            .content {
                & [class^="icon-"] {
                    background: var(--primary-base-color);
                }
            }
        }
    }
}
