/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.AdditionalInformationTab {
    padding-top: 25px;
}

.AdditionalInformation {
    &-Attribute {
        display: block;
        margin-bottom: 5px;
    }

    &-Title {
        font-weight: bold;
        display: inline-block;
        width: 10vw;
        @include mobile {
            width: 25vw;
        }
    }

    &-Item  {
        display: inline-block;
    }
}
