/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Popup {
    z-index: 10001; // Needed to fix Hello Retail issue

    &-Content {
        @at-root .VideoPopup & {
            @include desktop {
                overflow: hidden;
            }

            @include mobile {
                height: 0;
                padding-top: 56.3%;
                top: 50%;
                width: calc(100vw - 2.4rem);
                overflow: hidden;
                transform: translateY(-50%);
                background: transparent;
            }
        }
    }
}
