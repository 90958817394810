/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.NavigationTabs {
    &-LogoImg {
        @at-root body & {
            height: auto !important;
            width: auto !important;
            max-width: 180px;
            object-fit: contain;
        }
    }

    &-Button {
        &[aria-label='Minicart'] {
            @at-root body & { // Needs to be here to overwrite base theme
                height: 24px;
                width: 24px;
                padding: 0;
                margin-right: 0;
                margin-left: .6rem;
                order: 2;

                .Header-Button {
                    background-position: center;
                    height: 100%;
                    width: 100%;
                    display: block;
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
                    background-image: url('icons/Header-cart.svg');
                }
            }
        }
		&[aria-label='Go to menu and search'] {
            @at-root body & { // Needs to be here to overwrite base theme
				margin-right: 20px;
			}
		}
    }

    &-Icon {
        @at-root body & { // Needs to be here to overwrite base theme
            margin: 0;
        }
    }
}
