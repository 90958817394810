/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #000000;
$default-primary-base-color: #913D2A;
$default-primary-dark-color: #7A3424;
$default-primary-light-color: #a8462f;
$default-secondary-base-color: #FFFFFF;
$default-secondary-dark-color: #000000;
$default-secondary-light-color: #f0f0f0;
$default-terciary-base-color: #F5F1E7;
$default-terciary-dark-color: #949188;
$default-terciary-light-color: #fcf7ec;

$font-muli: 'Montserrat', sans-serif;
$font-family: 'forma-djr-display', serif;
$font-standard-size: 62.5%;
