/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --table-secondary-color: var(--theme-table-secondary-color);
}

table {
    tr:nth-child(even),
    thead tr {
        background-color: var(--table-secondary-color);
    }

    thead tr {
        border-bottom: 1px solid var(--secondary-dark-color);
    }

    td,
    th {
        padding: 10px;
        text-align: left;
        min-width: 12rem;

        @include mobile {
            min-width: 14rem;
        }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: scroll;
}
