.ConsultingBlock {
   //background-color: var(--terciary-darkbg-color); 

   &.VariantPicker {
    background-color: var(--primary-base-color);
    color: #fff;
    padding-bottom: 2.4rem;
    margin-bottom: 1.2rem;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0; 
        width: 100%;
        height: 100%;
        background-image: url(https://scontent-cph2-1.xx.fbcdn.net/v/t39.30808-6/299978055_3213511645554307_1857262572973853613_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=z6VTWwmxvykAX_MIUaO&_nc_ht=scontent-cph2-1.xx&oh=00_AT-EzJ-cfkl-ZyUpT1S1FWT7HnfsOp6LTVBoVdsV6Q1nwQ&oe=6330287E);
        z-index: 1;
        background-position: center 55%;
        background-size: cover;
        opacity: 0.06;
    }
    
    * {
        z-index: 5;
    }
    a {
        color: #fff;
    }
   }

    // &::before {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     background-image: url(https://ingvardchristensen.hoeks.dk/media/wysiwyg/blocks/consulting-background-1.png);
    //     background-size: cover;
    //     background-position: center;
    // }

    &-Header {
        display: grid;
        grid-template-columns: 1fr;
        padding: 3.6rem 100px 0;

        @at-root .VariantPicker & {
            text-align: center;
            margin-bottom: 2.4rem;
            padding-bottom: 1.2rem;
            position: relative;

            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 200px);
                height: 1px;
                background-color: rgba(255, 255, 255, .5);
            }
            
            h2 {
                color: #fff;
            }
        }
    
        @include mobile {
            display: block;
            padding: 2rem;

            @at-root .VariantPicker & {
                padding: 3.6rem 3.6rem 1.2rem;
                margin-bottom: 2.4rem;
            }
        }
    }

   &-Wrapper {
       display: grid;
       grid-template-columns: 1fr 1fr;
       padding: 0 100px;

       @at-root .VariantPicker & {
        grid-column-gap: 2.4rem;
       }

       @include mobile {
           display: block;
           padding: 2rem;

            @at-root .VariantPicker & {
                padding: 0 3.6rem;
            }
       }

   } 

   &-Details {
       padding: 50px 0;

        @at-root .VariantPicker & {
            padding: 0;
        }

       @include mobile {
           padding: 0;
       }

       h2 {
           font-size: var(--h1-font-size);
       }

       > p {
            a {
            font-weight: bold;
            &:hover {
                color: #000;
            }
           }
       }

   }

   &-Stores {
    //    display: grid;
    //    grid-template-columns: 1fr 1fr;
        display: flex;

       @include mobile {
            display: block;
            margin-top: 2rem;
            margin-bottom: 2rem;
            @at-root .VariantPicker & {
                margin: 0;
            }
       }

       @include desktop {
        @at-root .VariantPicker & {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 2.4rem;
        }
       }

   }

   &-StoresCol {
        @include desktop {
            margin-right: 4.8rem;

            @at-root .VariantPicker & {
                margin-right: 0;
            }
        }
   }

   &-Store {
    //    display: grid;
    //    grid-template-columns: 1fr 1fr;
       max-width: 400px;
       display: flex;

       > p {
           &:first-child {
               font-weight: 700;
               margin-right: 1.2rem;
           }
       }

       
            &.Full {
                grid-column-start: 1;
                grid-column-end: 3;
            }
       
   }

   &-ImgContainer {
       @include image-reset;
        height: 100%;
        position: absolute;
        right: 10vw;
        top: 0;

       @include mobile {
           padding-top: 0;
        }

        @include phone {
            display: none;
        }

       img {
           max-width: 240px;    
           margin-left: auto;

           @include mobile {
               margin: 0 auto;
            }
       }

   }

}