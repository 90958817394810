/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.CategoryBlock {
    display: block;
    padding: 0 25rem;
    &-Menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Menu-List-Item::before {
        content: '';
    }

    &-Menu-List-Item {
        float: left;
    }

    &-Groups {
        display: inline-block;
        width: 100%;
    }

    &-Group {
        border-bottom: #8AA5BD solid 1px;
        margin-bottom: 25px;
        h2 {
            display: block;
            float: left;
        }
    }

    &-GroupList {
        margin-bottom: 25px;
        -moz-column-count: 4;
        -moz-column-gap: 20px;
        -webkit-column-count: 4;
        -webkit-column-gap: 20px;
        column-count: 4;
        column-gap: 20px;

        li::before {
            content: '';
        }
    }
}