// remove annoying image reset 
.cms-section,
header,
footer {
    .Image {
        &:not(.Logo) {
            padding: 0;
            height: auto;
            display: block;
            width: auto!important;

            .Image-Image {
                position: relative;
                height: auto !important;
                object-fit: unset;
                display: block;
                width: auto!important;
            }
        }
    }
}

// no reset for slick slider 
.slick-slider {
    .Image {
        &:not(.Logo) {
            padding-bottom: 100%;
            height: 0!important;
            display: inline-block;
            width: 100%!important;

            @media screen and (max-width: 600px) {
				// This makes the whole card same height as the other picture cards on phone
                padding-bottom: #{'calc((50vw - 0.6rem) / 0.78 - 113px)'};
            }

			@include tablet {
				// This makes the whole card same height as the category picture cards on tablet
                padding-bottom: calc(290px - 113px);
            }

            .Image-Image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%!important;
                height: 100%!important;
                object-position: 50% 50%;
                object-fit: contain;
            }
        }
    }
}


