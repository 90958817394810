.MoebelhusEmployees {
    @include image-reset;
    margin-bottom: var(--standard-margin);

    &-EmployeeCardsContainer {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 2rem;
            grid-row-gap: 4rem;
        }

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2rem;
            grid-row-gap: 4rem;
        }

    }

    &-EmployeeCard {
        margin-bottom: 2.5rem;
        padding-bottom: 2.5rem;
        border-bottom: 1px solid var(--terciary-base-color-high-opacity);

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        @include desktop {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }

        .Image {
            .Image-Image {
                max-width: 100%!important;
            }
        }
    }

    &-Title {
        margin-bottom: 4rem;
    }

    &-EmployeeName {
        margin-top: 2rem;
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 0;
    }

    &-EmployeePosition {
        font-weight: bold;
        font-size: 1.3rem;
    }

    &-EmployeeEmail {
        margin-bottom: 0;
        a {
            color: #000;
            &:hover {
                color: #000;
            }
        }
    }

}