/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.VariantCustomFilterOption {
    &-Text {
        margin-top: 0;
        line-height: 38px;
        display: flex;
        justify-content: flex-start;
        font-size: 1.2rem;
        label {
            &:hover {
                cursor: pointer;
            }
        }
        .input-control {
            border-color: var(--body-heading-color)!important;
        }
    }
}