/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
@include mobile {
    .MenuOverlay {
        --header-nav-height: 80px;
        --menu-background-color: var(--terciary-base-color);
        --menu-background-dark-color: var(--terciary-darkbg-color);
        --menu-item-height: 40px;
        --menu-item-color: var(--body-content-color);
        --menu-item-border-color: var(--terciary-dark-border-color);
        --menu-usp-border-color: var(--terciary-dark-border-color);

        z-index: 200;
        height: auto!important;
        bottom: 0;
        position: absolute;

        &-Results {
            height: 100%;
            top: 0;
            bottom: 0;
            position: absolute;
            width: 100%;
        }

        &-Fader {
            @include mobile {
                height: 40px;
                width: 100%;
                position: absolute;
                background-color: rgba(245, 241, 231, .7);
                background: linear-gradient(rgba(245, 241, 231, .1), rgba(245, 241, 231, 1));
                bottom: 0;
                z-index: 50;
                pointer-events: none;
            }
        }

        .Menu {
            &-MenuWrapper {
                padding: 0;
                background: var(--menu-background-color);
                // height: 100%;
                // min-height: 100%;

                .Header {
                    &-Nav {
                        height: var(--header-nav-height)!important;
                        margin: 0;
                        padding: 0 1.2rem;
                        background: var(--menu-background-dark-color);
                        display: block;
                    }

                    &-Title {
                        height: var(--header-nav-height);
                        background: var(--menu-background-dark-color);
                        z-index: 2;
                        position: absolute;
                        top: 0;
                        left: 0;
                        max-width: 100%;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        color: var(--menu-item-color);
                        font-weight: var(--header-font-weight);
                        display: none;

                        &_isVisible {
                            display: flex;
                        }
                    }
                    
                    &-Button {
                        &_type {
                            &_back {
                                top: 50%;
                                transform: translateY(-50%);
                                z-index: 3;
                                background-image: url(icons/back.svg);
                                background-position: center;
                                background-size: 30px;
                                background-repeat: no-repeat;
                                padding: 0;
                                filter: invert(1);

                                &::before,
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            &-MainCategories {
                margin: 0;
                padding: 0;
            }

            &-Item {
                margin: 0;
                height: var(--menu-item-height);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-bottom: 1px solid var(--menu-item-border-color);
                padding: 0 1.2rem;
            }

            &-Link,
            &-SubCatLink {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 100%;
                width: 100%;
            }

            &-SubCatLink {
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    height: 30px;
                    width: 30px;
                    background-image: url(icons/plus.svg);
                    filter: invert(1);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 20px;
                    pointer-events: none;
                }
            }

            &-ItemCaption {
                &_type {
                    &_main {
                        color: var(--menu-item-color);
                        text-transform: uppercase;
                        font-size: 1.2rem;
                    }
                }
            }

            &-SubMenu {
                top: calc(var(--header-total-height) + var(--header-nav-height));
                height: calc(100vh - var(--header-total-height) - var(--header-nav-height));
                overflow: hidden;
                height: 100%;

                .Menu {
                    &-ItemList {
                        top: 0;
                        height: calc(100vh - var(--header-total-height) - var(--header-nav-height));
                        padding: 0;
                        display: block;
                        overflow-y: scroll;
                        padding-bottom: 50px;
                        background-color: var(--menu-background-color);
                        @include mobile {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            bottom: 0;
                            height: auto;
                            padding-bottom: 180px;
                        }
                    }

                    &-ItemFigure {
                        margin: 0;
                        height: var(--menu-item-height);
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-bottom: 1px solid var(--menu-item-border-color);
                        padding: 0 1.2rem;
                        margin: 0!important;

                        &_type {
                            &_subcategory {
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    right: 0;
                                    height: 30px;
                                    width: 30px;
                                    background-image: url(icons/plus.svg);
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-size: 20px;
                                    pointer-events: none;
                                    filter: invert(1);
                                }
                            }
                        }
                    }

                    &-ItemCaption {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        height: 100%;
                        width: 100%;
                        color: var(--menu-item-color);
                        text-transform: uppercase;
                        font-size: 1.2rem;
                    }

                    &-Link {
                        display: block;

                        &.hide-az {
                            display: none;
                        }
                    }
                }

                .Menu-SubMenu {
                    top: 0;
                }
            }
        }

        .ic-menu-item-image {
            display: none!important;
        }

        &-Usps {
            background-color: var(--menu-background-dark-color);
            min-height: 90px;
            max-height: 120px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        &-Usp {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &:not(:last-child) {
                border-right: 1px solid var(--menu-usp-border-color);
            }

            &_type {
                &_delivery {
                    .MenuOverlay-UspIcon {
                        background-image: url(icons/usps/delivery.svg);
                    }
                }
            }

            &_type {
                &_emaerket {
                    .MenuOverlay-UspIcon {
                        background-image: url(icons/usps/emaerket.svg);
                    }
                }
            }

            &_type {
                &_facebook {
                    .MenuOverlay-UspIcon {
                        background-image: url(icons/usps/facebook.svg);
                    }
                }
            }

            &_type {
                &_instagram {
                    .MenuOverlay-UspIcon {
                        background-image: url(icons/usps/instagram.svg);
                    }
                }
            }
        }

        &-UspIcon {
            display: block;
            height: 30px;
            width: 30px;
            background-position: center;
            background-size: 30px;
            background-repeat: no-repeat;
            filter: invert(1);
        }

        &-UspText {
            color: var(--menu-item-color);
            font-size: .8rem;
            font-weight: 500;
            text-align: center;
            line-height: 1.4;
            padding: 0 1.2rem;
        }
    }
}