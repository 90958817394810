/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.FurnitureHouesePicker {
    margin-bottom: 3rem;

    &-Heading {
        text-transform: none;
        font-weight: 400;
        font-size: 2rem;
        margin: 0 0 1.2rem;
        font-weight: 600;
        font-size: 1.6rem;
    }

    &-Inputs {
        margin-bottom: 1.5rem;
        .Field {
            .FieldSelect {
                &-Options_isExpanded {
                    max-height: 150px;
                }
            }
        }
    }
    
}