/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.snapengage {
    @media (min-width: 1024px) { 
        .contact-button {
            margin: 0 0 12px 12px;
            top: auto;
            bottom: 0;
        }

        .contact-popout {
            top: auto;
            bottom: 12px;
            left: 12px;
            padding-bottom: 40px;
        }

        .contact-popout .header {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }

    .icon-chat:before {
        content: "\e90a";
    }

    .contact-popout .header .icon-chat:before {
        content: "\e90a";
    }

    .contact-button {
        background: #15426c;
        color: #fff;
        text-align: center;
        display: block;
        width: 40px;
        height: 40px;
        position: fixed;
        outline: 0;
        top: 94%;
        z-index: 100;
        padding: 2px 0 0;
        left: 0;
        font-size: 25px;
    }

    .contact-popout {
        position: fixed;
        left: 0;
        top: 60%;
        width: 310px;
        background: #eeefef;
        z-index: 115;
        display: none;
    }

    .contact-popout-curtain {
        position: fixed;
        // left: 100%;
        top: 0;
        right: -1000px;
        // bottom: 100%;
        z-index: 114;
        height: 100vh;
        width: 3000px;
        display: none;
    }

    .contact-popout .header {
        background: #15426c;
        color: #fff;
        position: relative;
        font-size: 10px;
        text-align: center;
        padding: 14px 0;
        height: 40px;
    }

    .text-uppercase, .uppercase {
        text-transform: uppercase;
    }

    .contact-popout .content {
        text-align: center;
        padding: 20px 0 30px;
        font-size: 10px;
    }

    .contact-popout .content a {
        display: block;
        margin: 20px 0;
    }

    .contact-popout .content .icon-chat {
        font-size: 20px;
        padding: 8px 0 0;
    }

    .online {
        font-size: 10px;
    }

    .offline {
        font-size: 10px;
    }

    .contact-popout .content [class^=icon-] {
        background: #15426c;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        text-align: center;
        display: block;
        margin: 0 auto 3px;
        color: #fff;
    }

    [class*=" icon-"], [class^=icon-] {
        font-family: icomoon!important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .contact-popout .header button.close {
        position: Absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        font-size: 20px;
        color: #fff;
        padding: 7px 0;
        text-align: center;
    }

    .icon-close:before {
        content: "\e903";
    }
}