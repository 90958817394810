.UspBlockSmall {

    .mobile-specific {
        @include desktop {
            display: none!important;
        }
    }

    ul {
        display: flex;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            padding-left: 0;
            margin-bottom: 0;
            margin-right: 1rem;

            @include mobile {
                flex-direction: column;
                margin-right: 2rem;
            }

            & > *{
                margin-right: 1rem;

                &:last-child {
                    margin-right: 0;
                }

            }

            & > * {
                &:first-child {
                    @include mobile {
                        margin-bottom: 1rem;
                    }
                }
            }

            img {
                max-height: 28px;
            }


            p {
                margin-bottom: 0;
            }

            a {
                color: #000;
                &:hover {
                    color: #000;
                }
            }

            &:before {
                display: none;
            }

            &:after {
                @include desktop {
                    content: '|';
                    margin-left: 1rem;
                    color: #c3c3c3;
                }
            }

            &:last-child {
                &:after {
                    content:"";
                }
            }

        }

    }
}