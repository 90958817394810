/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.VariantProductList {
    &-Wrapper {
        overflow-x: hidden;
        overflow-y: scroll;

        @include desktop {
            height: 100%;

            &_isLoading {
                height: 100vh;
            }
        }

        &_noProducts {
            overflow: visible;
        }

        // /* Hide scrollbar */
        // &::-webkit-scrollbar {
        //     display: none; /* Chrome, Safari and Opera */
        // }
        // -ms-overflow-style: none;  /* IE and Edge */
        // scrollbar-width: none;  /* Firefox */
    }

    &-Qty {
        margin-bottom: .3rem;
        font-size: 1.2rem;
    }

    &-Empty {
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
        margin-top: 4.8rem;
        margin-bottom: 4.8rem;

        p {
            font-size: 1.6rem;
            line-height: normal;
            margin-bottom: 1rem;
            &:first-child {
                font-size: 2.4rem;
                margin-bottom: 3rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}