/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Field {

    &-Label {
        &_isRequired {
            &::after {
                content: ' *';
            }
        }

        @at-root .isCheckout & {
            font-weight: var(--font-weight);
            padding-bottom: 0;
            font-size: 1.1rem;
        }
    }

    &-Message {
        @at-root .isCheckout & {
            font-size: 1.1rem;
            font-weight: 500;
            color: #792727;
        }
    }

    &_isValid {
        input,
        textarea {
            border: 1px solid var(--option-border-color);
            background: transparent;
        }
    }

    .CheckoutAddressForm & {
        @include desktop {
            padding: 0 1rem!important;
        }
    }

    &_name_NewsLetter_Toggle {
        font-size: 1.4rem;
        margin-top: 0;
        margin-bottom: 2.4rem;
        .input-control {
            bottom: 1px;
        }
    }

}

.FieldForm-Fields {
    @at-root body .CheckoutAddressForm & {
        @include desktop {
           margin: 0 -.6rem;
        }
    }

    @at-root .CheckoutGuestForm & {
        margin-bottom: .6rem;
    }
}

.CheckoutAddressForm {
    margin-bottom: 2rem;
}


