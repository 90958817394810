/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.DiscountSign {
    height: 10rem;
    position: fixed;
    z-index: 190;
    &-Link {
        float: right;
        right: 0;
        position: fixed;
        line-height: 1;

        span {
            display: block;
            font-weight: bold;
        }
    }

    &-Link:hover {
        color: inherit;
    }

    &-first {
        @at-root .hideDiscountSign & {
            display: none;
        }
    }

    &-second {
        margin-top: 75px;
    }
}