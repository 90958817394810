/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.HelloRetail {
    @include desktop {
        // margin-top: 4.8rem;
        margin-bottom: var(--section-spacing);
    }

    .full-width-container--blue & {
        margin-bottom: 0;
    }

    .isProductPage & {
        padding: 40px 0;
        background-color: #f5f5f5;

        .slick-slider {
            .slick-list {
                &:after {
                    background-color: #f5f5f5!important;
                }
            }
        } 
    }

    .isCategoryPage & {
        margin-top: 180px;

        @include desktop {
            margin-top: 0;
            margin-bottom: 7rem;
        }

    }

    &-Wrapper {
        .isCategoryPage & {
            padding: 0; 
        }
    }
    
    &-Title {
        padding: 1.2rem 0;
        color: var(--expandable-content-color);
        font-size: 1.4rem;
        line-height: 1.4;
        color: var(--body-heading-color)!important;
        font-weight: 600!important;
        font-size: var(--h4-font-size);
        padding-top: 0;
        

        @include desktop {
            font-size: var(--h3-font-size);
            margin: 0;
            padding: 1.2rem 3.5rem 3.5rem 0;
            border-color: #000;
            color: #000;
            margin-bottom: 3.5rem;
            border-bottom: 1px solid #e7e7e7;
        }

        .isCategoryPage {
            padding: 0;

            @include desktop {
                padding-bottom: 3.5rem;
            }
        }

    }

    &-Slider {
        .slick-slider {
            padding: 0;

            @include mobile {
                margin: 0 5px; 
            }

            .isCategoryPage & {
                @include mobile {
                    margin: 0 10px; 
                }
            }

            .ProductCard {
                padding: 0 20px;
                background-color: #fff;
                margin: 0 20px;
                box-shadow: 0px 0px 21px 0px rgba(51, 51, 51, 0.15);
                height: 100%;

                @include before-desktop {
                    margin: 10px;
                    margin-right: 40px;
                }

                @include mobile {
                    padding: 10px; 
                    margin: 5px;
                    box-shadow: 0px 0px 3px 0px rgba(51, 51, 51, 0.15);
                }

                &-Figure {
                    @include desktop {
                        margin-top: 2rem;
                    }
                }
            }

            .slick-list {
                overflow: visible;
                padding: 1rem 0;
                @include desktop {
                    margin-left: -2rem;
                    margin-right: -2rem;
                }

                @include mobile {
                    overflow: hidden;
                    margin: 0 -10px;
                }

                &:after {
                    content: ' ';
                    position: absolute;
                    top: -20px;
                    bottom: -20px;
                    width: 4000px;
                    right: 100%;
                    background-color: white;

                    @include mobile {
                        display: none;
                    }
    

                }

            }

            .slick-arrow {
                height: 25px;
                width: 25px;
                &.slick-next {
                    right: -5px;
                    top: -90px;

                    @include mobile {
                        top: 50%;
                        right: -20px;
                        left: auto;
                    }

                }

                &.slick-prev {
                    left: auto;
                    right: 34px;
                    top: -90px;;

                    @include mobile {
                        top: 50%;
                        right: auto;
                        left: -20px;            
                    }

                }

                &.slick-disabled {
                    @include mobile {
                        opacity: 0;
                        transition: .2s ease-in all;
                    }
                }

            }

        }

        .isCategoryPage & {
            .slick-slider {
                .slick-list { 
                    overflow: hidden;
                    margin-left: -2rem;
                    margin-right: -2rem;
                }
                .slick-arrow {
                    &.slick-next {
                        right: -5px;

                        @include mobile {
                            right: -20px;
                        }

                    }

                    &.slick-prev {
                        right: 34px;
                    }

                }
            }
        }

    }
}