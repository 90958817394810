.MoebelhusIntro {
    margin-bottom: var(--standard-margin);
    @include desktop {
        display: flex;
        margin-bottom: 0;
        align-items: stretch;
    }

    &-Content {
        background-color: var(--terciary-base-color-opacity);
        padding: 5rem 2.5rem;
        @include desktop {
            width: 40%;
        }
    }

    &-Top {
        margin-bottom: 2.5rem;
        p {
            margin-bottom: 0;
            font-weight: bold;
        }
    }

    &-OpeningTime {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &:last-child {
            p {
                margin-bottom: 0;
            }
        }
    }

    &-OpeningTimes {
        margin-bottom: 2rem;
    }

    &-OpeningNotes {
        margin-bottom: 2rem;
        p {
            font-size: 1.2rem;
        }
    }

    &-ReadMore {
        margin-bottom: 2rem;
        font-weight: bold;
        a {
            color: #000;
            font-size: var(--p-font-size);
            &:hover {
                text-decoration: none;
                color: #000;
            }
        }
    }

    &-Actions {
        display: flex;

        @include mobile {
            display: block;
        }

        .RenderWhenVisible {
            margin-right: 2rem;

            @include mobile {
                margin-right: 0;
                margin-bottom: 1rem;
            }

            &:last-child {
                margin-right: 0;
                @include mobile {
                    margin-bottom: 0
                }
            }
            .ScrollToWidget-Link {
                @include cms-btn;
            }
        }
    }

    &-Slider {
        // background-color: black;
        @include desktop {
            width: 60%;
        }

        .RenderWhenVisible {
            @include desktop {
                height: 100%;
            }
        }

        .slick-slider {
            @include desktop {
                height: 100%;
            }

            .slick-slide {
                @include desktop {
                    padding: 0;
                }
            }

            .slick-list {
                @include desktop {
                    height: 100%;
                    margin: 0;
                }

                .slick-track {
                    @include desktop {
                        height: 100%;
                    }

                    div,
                    figure {
                        @include desktop {
                            height: 100%!important;
                        }
                    }

                    .Image {
                        padding-bottom: 0;
                        height: 100%!important;
                    }
                    
                    .Image-Image {
                        position: relative!important;
                        width: 100%!important;
                        height: 100%!important;
                        object-fit: cover;
                    }
                }
            }
            .slick-dots {
                display: none!important;
            }
        }


    }

}