.FeaturedProducts {
    margin-bottom: 1.8rem;
    height: 0;
    padding-bottom: calc((100% / 1.5 ) * 4);

    @include tablet {
        padding-bottom: calc((100% / 3.75 ) * 4);
    }

    @include desktop {
		margin-bottom: 4.8rem;
        padding-bottom: calc((100% / 5 ) * 3);
    }

    .CmsPage .CmsPage-Content & {
        .Image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100% !important;
            padding-bottom: 0;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0);
            }

            .Image-Image {
                width: calc(100% + 2px) !important;
                height: calc(100% + 2px) !important;
                object-fit: cover !important;
                top: -1px;
                left: -1px;
                @include desktop {
                    transition: ease-in all .2s;
                }
            }
        }
    }

    &-Wrapper {
        display: grid;
        height: 100%;
        width: 100%;
        gap: 1.6rem;
        position: absolute;
        top: 0;
        left: 0;
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(2, 1fr);

        @include desktop {
            gap: 3.6rem;
            grid-template-rows: repeat(3, 1fr);
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-rows: repeat(4, 1fr);
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-Element {
        position: relative;
        height: 100%;

        &:hover {
            .Image-Image {
                @include desktop {
                    transform: scale(1.05);
                    transition: ease-in all .2s;
                }
            }
        }

        &:nth-child(1) {
            grid-column: 1/3;
            grid-row: 1/2;

            @include tablet {
                grid-column: 1/3;
                grid-row: 1/3;
            }

            @include desktop {
                grid-column: 1/3;
                grid-row: 1/3;
            }

            .design2 & {
                grid-column: 1/3;
                grid-row: 1/2;

                @include tablet {
                    grid-column: 1/3;
                    grid-row: 1/3;
                }

                @include desktop {
                    grid-column: 1/3;
                    grid-row: 1/3;
                }
            }
        }

        &:nth-child(2) {
            grid-column: 1/2;
            grid-row: 2/3;

            @include tablet {
                grid-column: 3/4;
                grid-row: 1/2;
            }

            @include desktop {
                grid-column: 3/4;
                grid-row: 1/2;
            }

            .design2 & {
                grid-column: 1/2;
                grid-row: 4/5;

                @include tablet {
                    grid-column: 3/4;
                    grid-row: 1/2;
                }

                @include desktop {
                    grid-column: 3/4;
                    grid-row: 1/2;
                }
            }
        }

        &:nth-child(3) {
            grid-column: 2/3;
            grid-row: 2/3;

            @include tablet {
                grid-column: 3/4;
                grid-row: 2/3;
            }

            @include desktop {
                grid-column: 3/4;
                grid-row: 2/3;
            }

            .design2 & {
                grid-column: 2/3;
                grid-row: 2/3;

                @include tablet {
                    grid-column: 3/4;
                    grid-row: 3/5;
                }

                @include desktop {
                    grid-column: 3/4;
                    grid-row: 2/4;
                }
            }
        }

        &:nth-child(4) {
            grid-column: 1/2;
            grid-row: 3/4;

            @include tablet {
                grid-column: 1/2;
                grid-row: 3/5;
            }

            @include desktop {
                grid-column: 4/5;
                grid-row: 1/3;
            }

            .design2 & {
                grid-column: 2/3;
                grid-row: 4/5;
    
                @include tablet {
                    grid-column: 3/4;
                    grid-row: 2/3;
                }
    
                @include desktop {
                    grid-column: 4/5;
                    grid-row: 1/2;
                }
            }
        }

        &:nth-child(5) {
            grid-column: 2/3;
            grid-row: 3/4;

            @include tablet {
                grid-column: 2/3;
                grid-row: 4/5;
            }

            @include desktop {
                grid-column: 1/2;
                grid-row: 3/4;
            }

            .design2 & {
                grid-column: 2/3;
                grid-row: 5/6;
    
                @include tablet {
                    grid-column: 1/2;
                    grid-row: 3/4;
                }
    
                @include desktop {
                    grid-column: 4/5;
                    grid-row: 2/3;
                }
            }
        }

        &:nth-child(6) {
            grid-column: 1/2;
            grid-row: 5/6;

            @include tablet {
                grid-column: 3/4;
                grid-row: 4/5;
            }

            @include desktop {
                grid-column: 2/3;
                grid-row: 3/4;
            }

            .design2 & {
                grid-column: 1/2;
                grid-row: 3/4;
    
                @include tablet {
                    grid-column: 2/3;
                    grid-row: 4/5;
                }
    
                @include desktop {
                    grid-column: 1/2;
                    grid-row: 3/4;
                }
            }
        }

        &:nth-child(7) {
            grid-column: 1/3;
            grid-row: 4/5;

            @include tablet {
                grid-column: 2/4;
                grid-row: 3/4;
            }

            @include desktop {
                grid-column: 3/5;
                grid-row: 3/4;
            }

            .design2 & { 
                grid-column: 2/3;
                grid-row: 3/4;
    
                @include tablet {
                    grid-column: 1/2;
                    grid-row: 4/5;
                }
    
                @include desktop {
                    grid-column: 2/3;
                    grid-row: 3/4;
                }
            }
        }

        &:nth-child(8) {
            .design2 & { 
                grid-column: 2/3;
                grid-row: 6/7;
    
                @include tablet {
                    grid-column: 2/3;
                    grid-row: 3/4;
                }
    
                @include desktop {
                    grid-column: 4/5;
                    grid-row: 3/4;
                }
            }
        }
    }

    &-Content {
        position: absolute;
        bottom: 0;
        left: 1rem;
        color: #fff;

        @include desktop {
            left: 3rem;
            bottom: 1rem;
        }

        p {
            font-size: 1.1rem;
            line-height: 1.2;

            @include tablet {
                font-size: 1.2rem;
            }

            @include desktop {
                font-size: 1.3rem;
            }
        }

        h3 {
            color: #fff;
            font-size: var(--h4-font-size) !important;
            margin-bottom: 2px;

            @include tablet {
                font-size: var(--h3-font-size) !important;
            }

            @include desktop {
                font-size: var(--h2-font-size) !important;
            }
        }
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
}
