.MoebelhusDescriptionMap {
    margin-bottom: var(--standard-margin);

    @include desktop {
        display: flex;
    }


    &-Map {
        @include desktop {
            width: 40%;
        }
        @include mobile {
            margin-bottom: 2rem;
        }
    }

    &-Description {
        @include desktop {
            width: 60%;
            background-color: var(--terciary-base-color-opacity);
            padding: 5rem 10rem;
        }
    }

    &-DescriptionText {
        @include desktop {
            column-count: 2;
            column-gap: 40px;
        }
    }

    &-Map {
        background-color: #000;

        .RenderWhenVisible {
            height: 100%;

            @include mobile {
                height: 300px;
            }

            & > div {
                height: 100%;

                .gm-style-iw-d {
                    h3 {
                        font-size: 1.5rem;
                        margin-bottom: 1rem;
                    }
                }

                .gm-style-iw {
                    border-radius: 2px!important;
                }

            }
        }
    }

    &-MapAddress {
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 0;
        background-color: var(--primary-base-color);
        color: #fff;
        padding: .5rem 1rem;
        z-index: 2;
    }

}