:root {
    // Content Width
    --cms-content-padding: 1.4rem;
    --cms-width: var(--content-wrapper-width);
    --cms-max-width: calc(100% - (var(--cms-content-padding) * 2));
    --cms-full-width: 100%;
    --cms-full-max-width: 100%;

    // Content Height
    --height-100vh: calc(100vh - var(--header-nav-height));
    --bottom-mobile-navigation: var(--navigation-tabs-height);

    // Spacing
    --cms-spacing-x1: 1.2rem;
    --cms-spacing-x2: 2.4rem;
    --cms-spacing-x3: 4.8rem;
    --cms-col-content-x1: 2.4rem;
    --cms-col-content-x2: 4.8rem;
    --cms-col-content-x3: 9.6rem;
    --cms-vertical-padding-x1: 2.4rem;
    --cms-vertical-padding-x2: 4.8rem;
    --cms-vertical-padding-x3: 9.6rem;

    // Box Shadow
    --cms-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .15);

    // Colors
    --cms-grey-background: var(--grey-background-main);
    --cms-white-background: var(--white);
    --cms-theme-color-1-background: var(--primary-base-color);
    --cms-theme-color-1-text: var(--primary-base-color);
    --cms-light-font-color: var(--body-content-color-light);

    // Button - General
    --cms-button-border-radius: 0;
    --cms-button-font-weight: var(--button-font-weight);
    --cms-button-font-size: var(--button-font-size);
    --cms-button-font-size-mobile: 1.54rem;
    --cms-button-line-height: normal;
    --cms-button-padding: var(--button-padding);
    --cms-button-border-width: var(--button-border-width);
    --cms-button-border-style: solid;
    --cms-button-spacing: var(--cms-spacing-x2);

    // Button - Primary
    --cms-button-primary-color: var(--button-color);
    --cms-button-primary-background-color: var(--button-background);
    --cms-button-primary-border-color: var(--button-border);

    // Button - Primary Hover
    --cms-button-primary-hover-color: var(--button-hover-color);
    --cms-button-primary-hover-background-color: var(--button-hover-background);
    --cms-button-primary-hover-border-color: var(--button-hover-border);

    // Button - Secondary
    --cms-button-secondary-color: var(--primary-base-color);
    --cms-button-secondary-background-color: var(--white);
    --cms-button-secondary-border-color: var(--primary-base-color);

    // Button - Secondary Hover
    --cms-button-secondary-hover-color: var(--primary-dark-color);
    --cms-button-secondary-hover-background-color: var(--secondary-light-color);
    --cms-button-secondary-hover-border-color: var(--primary-dark-color);

    // Button - Light Hollow
    --cms-button-light-hollow-color: var(--white);
    --cms-button-light-hollow-background-color: transparent;
    --cms-button-light-hollow-border-color: var(--white);

    // Button - Light Hollow Hover
    --cms-button-light-hollow-hover-color: var(--primary-base-color);
    --cms-button-light-hollow-hover-background-color: var(--white);
    --cms-button-light-hollow-hover-border-color: var(--primary-base-color);
}
