/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
:root {
    --wishlist-heart-size: 12px;
    --wishlist-heart-background: var(--primary-base-color);
}

.AddToWishlistButton {
    --loader-scale: .6;

    margin-left: 1.08rem;
    vertical-align: middle;
    display: inline-block;

    @include mobile {
        margin-left: 1.26rem;
    }

    @include tablet-portrait {
        margin-top: 1.2rem;
    }

    &-Button {
        --button-padding: 0;
        --button-hover-background: transparent;
        --button-hover-border: var(--primary-dark-color);

        height: 47px;
        width: 47px;

        &_isInWishlist {
            --wishlist-heart-background: #fff;
            --button-hover-background: var(--primary-dark-color);
            --button-hover-border: var(--primary-dark-color);

            &:hover {
                @include after-mobile {
                    --wishlist-heart-background: #fff;
                }
            }
        }

        &.Button {
            border-radius: 50%;
        }
    }

    &:hover {
        @include after-mobile {
            --wishlist-heart-background: var(--primary-dark-color);
        }
    }

    &-Heart {
        transform: rotate(-45deg);
        bottom: 0;
        top: 1px;
        margin: auto;

        &,
        &::after,
        &::before {
            height: var(--wishlist-heart-size);
            width: var(--wishlist-heart-size);
            background-color: var(--wishlist-heart-background);
        }

        &::after,
        &::before {
            content: '';
            border-radius: 50%;
            position: absolute;
        }

        &::before {
            top: calc(var(--wishlist-heart-size) / -2);
            right: 0;
        }

        &::after {
            right: calc(var(--wishlist-heart-size) / -2);
            top: 0;
        }
    }
}