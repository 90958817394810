/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Breadcrumbs {
    .isCategoryPage & {
        //background-color: var(--terciary-darkbg-color);
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .isProductPage & {
        //background-color: var(--terciary-base-color-opacity);
        padding-top: 2rem;

        .ContentWrapper {
            @include wide-wrapper;
            @include mobile-padding;
        }
    }

    .isCmsPage & {
        display: none;

        .ContentWrapper {
            @include wide-wrapper;
            @include mobile-padding;
        }
    }

    @at-root .isBlogOverview & {
        display: none;
    }

    @at-root .Header-Wrapper_name_posts-details + & {
        display: none;
    }
}
