/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
:root {
    @include mobile {
        --header-background: var(--terciary-base-color);
    }
}

.ProductActions {
    &-Section_type_kontakt {
        p {
            font-size: large;
            font-weight: bold;
        }
    }

    &-Price {
        @include mobile {
            font-size: 2rem;
        }
    }

    &-PriceLabel {
        margin: 0;
        margin-left: 2rem;
        background-color: var(--primary-base-color);
        color: #fff;
        padding: .3rem 1rem;
        font-size: 1.1rem;
        text-transform: none;

        @include mobile {
            margin-left: 0;
            margin-bottom: 1rem;
        }

		@include badge-colors;
    }

    &-PriceContainer {
        display: flex;
        align-items: center;
        min-height: 30px;

        @include mobile {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }

    &-BrandAndDesigner {
        display: flex;
        order: 2;

        a {
            display: inline-block;
			color: var(--body-content-color);

			&:hover {
				color: var(--body-content-color);
			}

            &:first-child {
                p {
                    margin-left: 0;
                }
            }

        }

        p {
            display: inline-block;
            margin-left: 5px;
        }
    }

    &-Section {
        &_type_name {
            padding-top: 0;
            @include mobile {
                padding: 0;
                margin-bottom: 0;
                border-top: none;
            }
        }

        &_type_sku {
            display: none;
        }

        &_type_customizable_options:empty {
            display: none;
        }
    }

    &-Title {
        font-size: 3.5rem;
        @include mobile {
            font-size: 2.5rem;
            line-height: normal;
        }
    }

    &-Schema {
        order: 8;
        @include mobile {
            order: 0;
            margin-bottom: 1rem;
        }
    }

    &-AttributesWrapper {
        @include desktop {
            max-width: 450px;
            margin-top: 2.4rem;
            margin-bottom: 2.4rem;
        }
        @include mobile {
            margin-bottom: 2rem;
        }
    }

    &-AttributesValuesWrapper {
        @include desktop {
            order: 6;
            margin-bottom: -0.6rem;
        }

        .ProductConfigurableAttributesDetails-Wrapper {
            @include desktop {
                margin-top: 1.2rem;
            }
        }

        @include mobile {
            order: -1;
        }
    }

    &-AddToCartWrapper {
        @include desktop {
            max-width: 450px;
            margin-right: 0;
            display: flex;
        }
    }

    &-Qty {
        @include desktop {
            order: -1;
            height: 100%;
        }
    }

    &-AddToCart {
        @include desktop {
            flex-grow: 1;
            &.Button {
                font-size: var(--h3-font-size);
                padding: 1rem 6rem;
            }
        }

        &.Button:disabled {
            opacity: 1;
            background-color: #B4B4B4;
            border-color: #B4B4B4;
        }
    }

    &-PriceWrapper {
        @include desktop {
            flex-direction: row;
            justify-content: flex-start;
            min-height: 0;
            margin-top: 1.2rem;
        }
        @include mobile {
            padding: 0;
        }
    }

    &-ConfigurablePriceBadge {
        font-size: 2rem;
        margin-right: 0.6rem;

        @include desktop {
            font-size: 2.5rem;
        }
    }

    &-ChoseVariantMessage {
        order: 20;
    }

    &-BrandAndDesigner {
        margin-top: .5rem;
        @include mobile {
            order: -1;
            margin-bottom: 2rem;
        }

        a p {
            @include mobile {
                margin: 0;
            }
        }
    }

    &-ImportantMessage {
        order: 11;
    }

    &-DiscountPeriod {
        order: 12;
    }

    &-StockStatus {
        order: 10;
        margin-top: .5rem;

        @include mobile {
            order: 0;
            margin-bottom: 2.4rem;
            margin-top: 0;
        }

        p {
            margin: 0;
        }
    }

    .Field_isValid {
        input[type="number"] {
            border-color: var(--theme-border-color);
        }
    }
}
