/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../style/abstract/variables';
 @import '../../../style/abstract/media';
 @import '../../../style/abstract/mixins-custom';
 @import '../../../style/abstract/button';
 @import '../../../style/abstract/loader';
 @import '../../../style/abstract/icons';
 @import '../../../style/abstract/parts';
 @import '../../../style/abstract/theme-abstract';
 
.BlogRecentPosts {
    &-PostTitle {
        font-weight: var(--header-font-weight);
    }

    &-PostCard {
        padding: 0;
        flex-direction: column;
        border: none !important;

        .Image {
            object-fit: cover;
            height: 0;
            padding-bottom: 350px;
            display: block;
        }

        .Image-Image {
            object-fit: cover;
        }
    }

    &-ImageWrapper {
        flex-basis: 100%;
        width: 100%;
    }

    &-Date {
        position: relative;
        border: unset;
    }

    @at-root .BlogRecentPosts-Wrapper & {
        &-Wrapper {
            height: 100%;
            background: #fff;
            padding: 2.4rem;
        }
    }
}

.PostsDetails-Sidebar > .BlogRecentPosts-Wrapper {
    flex-basis: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}
