/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/mixins-custom';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
.ClerkSearch {
    .isCheckout & {
        display: none;
    }

    @include mobile {
        position: absolute;
        top: 0;
        left: 1.2rem;
        width: calc(100% - 2.4rem);
        height: 100%;
        align-items: center;
        justify-content: center;
        margin: 0;
        z-index: 1;

        @at-root .MenuOverlay_isActive & {
            display: flex;
        }
    }

    &-InputWrapper {
        @include mobile {
            width: 100%;
        }
    }

    &-Input {
        border-radius: 0;
        min-width: 400px;
        background-color: var(--terciary-base-color);

        @include mobile {
            min-width: 0;
            width: 100%;
        }

        @include largescreens {
            min-width: 500px;
        }

        @include mobile {
            border: none;
            padding-left: 1.2rem;
        }

        &-Icon {
            width: 16px;
            height: 16px;
        }
    }

    &-Results {
        top: calc(100% + 15px);
        transform: translate(-50%, 0);
        left: 50%;
        display: grid;
        grid-template-columns: 12fr 8fr;
        width: 90vw;
        max-width: 1000px;
        border-radius: 5px;

        @include mobile {
            box-shadow: none;
            border: none;
            top: calc(100% - 20px);
            width: 100%;
            left: 0;
            transform: none;
            max-height: 80vh;
            overflow-x: auto;
            overflow-y: scroll;
        }

        @include phone {
            grid-template-columns: 1fr;
            border-radius: 0;
        }

        .SearchItem {
            margin: 0;
            border-bottom: 1px solid rgba(0, 0, 0, .05);

            .Image {
                width: 50px!important;
                height: 50px!important;
                padding-bottom: 0;
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border: none;
                padding-bottom: 0;
            }

            &-Wrapper {
                min-height: 50px;
            }
        }
    }

    &-ResultHeading {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    &-ResultColumn {
        padding: 2.4rem;

        &_type {
            &_leftColumn {
                border-bottom: 1px solid rgba(0, 0, 0, .05);
                border-right: 1px solid rgba(0, 0, 0, .05);

                @include phone {
                    border-right: none;
                }
            }

            &_rightColumn {
                border-bottom: 1px solid rgba(0, 0, 0, .05);
                padding: 0;
            }

            &_bottom {
                grid-column: 1 / 3;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                display: none;

                @include phone {
                    grid-column: 1 / 2;
                }
            }
        }
    }

    &-ResultContainer {
        &_type {
            &_suggestions {
                padding: 2.4rem;
                border-bottom: 1px solid rgba(0, 0, 0, .05);
            }

            &_categories {
                padding: 2.4rem;
            }
        }
    }

    &-SuggestionItem,
    &-CategoryItem {
        padding: 0;

        &::before {
            display: none;
        }
    }

    &-SuggestionItem {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
