/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Breadcrumb {
    font-size: 1.4rem;
    color: #616161;
    padding-right: 8px;

    &:not(:last-child) {
        &:after {
            content: "/";
            height: auto;
            width: auto;
            border: 0;
            transform: none;
            top: 0;
            margin-left: 8px;
            color: #616161;
        }
    }

    &-Link {
        color: #616161;

        &:hover {
            color: #616161;
        }

    }

}