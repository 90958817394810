/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../style/abstract/variables';
 @import '../../../style/abstract/media';
 @import '../../../style/abstract/mixins-custom';
 @import '../../../style/abstract/button';
 @import '../../../style/abstract/loader';
 @import '../../../style/abstract/icons';
 @import '../../../style/abstract/parts';
 @import '../../../style/abstract/theme-abstract';
 
.BlogCategories {
    &-Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 -5px;
        margin-bottom: -10px;
        flex-wrap: wrap;

        // @include mobile {
        //     justify-content: flex-start;
        // }
    }

    &-Category {
        border: none;
        padding: 0 5px;
        margin-bottom: 10px;

        a {
            text-decoration: none !important;

            &:hover {
                text-decoration: none;
            }
        }

        &::after {
            display: none;
        }

        &:last-child {
            border: none;
        }
    }

    &-CategoryTitle {
        padding: 1.2rem 2.4rem;
        background: var(--terciary-base-color-opacity);
        margin: 0;
        font-size: 1.1rem;
        font-weight: 600;
        transition: all ease-out .25s;
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
            background-color: var(--primary-base-color);
            color: #fff;
        }
    }
}
