/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../style/abstract/variables';
 @import '../../../style/abstract/media';
 @import '../../../style/abstract/mixins-custom';
 @import '../../../style/abstract/button';
 @import '../../../style/abstract/loader';
 @import '../../../style/abstract/icons';
 @import '../../../style/abstract/parts';
 @import '../../../style/abstract/theme-abstract';
 
.BlogPostCard {
    // &:not(:last-child) {
    //     &::after {
    //         content: '';
    //         position: absolute;
    //         bottom: calc((var(--blog-grid-gap) / 2) * -1);
    //         width: 100%;
    //         height: 1px;
    //         background: rgba(0, 0, 0, .07);
    //     }
    // }

    &.BlogPostCard_isFeatured {
        grid-column: span 2;

        @include phone {
            grid-column: span 1;
        }
    }

    &-ContentWrapper {
        height: 100%;
    }

    &-InnerWrapper {
        height: 100%;
        background: var(--terciary-base-color-opacity);
    }

    &-ImageWrapper {
        display: block;
    }

    &-Image {
        height: 0;
        padding-bottom: 350px;

        > img {
            display: block;
            position: absolute;
        }
    }

    &-Details {
        padding: 2.4rem;
    }

    &-DescriptionWrapper {
        max-height: 1000px;
    }
}
