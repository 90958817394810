/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
:root {
    --color-product-conf-attr-popup-main: #eeedeb;
}
.ProductConfigurableAttributesPopup {
    &-SelectorButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        font-weight: var(--button-font-weight);
        font-size: var(--button-font-size);
        line-height: normal;
        text-transform: uppercase;
        padding: 1rem 3rem;
        color: var(--button-hover-color);
        background-color: var(--button-hover-background);
        transition-property: background-color, color, border;
        will-change: background-color, color, border;
        transition-timing-function: ease-out;
        transition-duration: .25s;
        cursor: pointer;
        border-width: var(--button-border-width);
        border-style: solid;
        border-color: var(--button-hover-border);
        border-radius: var(--theme-border-radius-button);
        text-transform: uppercase;
        letter-spacing: var(--heading-letter-spacing);
        text-align: center;

        &::before {
            content: '';
            display: inline-block;
            position: relative;
            height: 20px;
            width: 20px;
            background-image: url(icons/tune.svg);
            background-position: center;
            background-size: cover;
            margin-right: .6rem;
            transition-timing-function: ease-out;
            transition-duration: .25s;
        }

        &:hover {
            border-color: var(--button-hover-border);
            background-color: var(--button-background);
            color: var(--button-color);

            &::before {
                background-image: url(icons/tune-white.svg);
            }
        }
    }
    &-Wrapper {
        .TextPlaceholder {
            &_length_custom  {
                &:after {
                    content: "";
                    height: 46px;
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    &-ContentWrapper {
        @include mobile {
            height: calc(100% - 30px);
            padding: var(--popup-content-padding);
            overflow-y: scroll;
        }

        @include desktop {
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }

        .Loader {
            @include mobile {
                top: 95px;
            }
        }
    }

    &-Popup {
        .Popup {
            &-Content {
                @include mobile {
                    overflow: hidden;
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 0;
                    padding-top: 0;
                }
                width: 80vw;
                box-shadow: #eaeaea 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.047) 0px 2.6px 2.5px, rgba(0, 0, 0, 0.04) 0px 5.3px 5.1px, rgba(0, 0, 0, 0.03) 0px 11px 10.6px, rgba(0, 0, 0, 0.024) 0px 30px 29px;
                background-color: var(--color-product-conf-attr-popup-main);
                @include desktop {
                    height: 80%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    padding-right: 0.8rem;
                }
            }

            &-Header {
                margin-bottom: 2.4rem;
                @include mobile {
                    display: block;
                    margin-top: 1.4rem;
                    padding-left: 1.4rem;
                    padding-right: 1.4rem;
                    height: 30px;
                    margin-bottom: 0;
                }
            }

            &-Heading {
                font-weight: 600;
                margin-bottom: 0;
                @include mobile {
                    font-size: 1.3rem;
                    padding-right: 4rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            &-CloseBtn {
                @include mobile {
                    top: -8px;
                    right: 1rem;
                }
                @include desktop {
                    right: 0.6rem;
                }
            }

        }
    }
}