/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/mixins-custom';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
.CategoryBlock {
    padding: 0;
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 9.6rem;

    &-Menu {
        &-List {
            text-align: center;

            &-Item {
                padding: 0;
                float: unset;
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 1rem;
                }

                h3 {
                    font-size: var(--h2-font-size);
                    font-weight: 600;
                }
            }
        }
    }

    &-Groups {
        max-width: 1000px;
        margin: 0 auto;
        display: block;
    }

    &-Group {
        margin-top: 4.8rem;
        margin-bottom: 0;
        padding-bottom: 4.8rem;
        border-bottom: 1px solid rgba(0, 0, 0, .05);

        h2 {
            width: 100px;

            @include mobile {
                font-size: 4rem !important;
            }

            @include desktop {
                font-size: 5rem;
            }
        }
    }

    &-GroupList {
        @include mobile {
            column-count: 3;
        }

        @include phone {
            column-count: 2;
        }

        @media screen and (max-width: 500px) {
            column-count: 1;
        }

        li {
            margin-bottom: 0;

            a {
                color: var(--body-content-color);
                font-size: 1.3rem;
                line-height: 1.2;
                display: block;
                margin-bottom: 1rem;
            }
        }
    }
}
