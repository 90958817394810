/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
:root {
    --option-text-color: var(--body-heading-color);
}

.ProductConfigurableAttributes {
    @at-root .CategoryPage & {
        @include desktop {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &-Expandable {
                margin-right: 2rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            &-ExpandableContentButton {
                cursor: pointer;
                margin-right: 2rem;

                &.ExpandableContent-Button_isContentSelected {
                    border-color: #333;

                    + .ProductConfigurableAttributes-ExpandableContentContent {
                        border-color: #333;
                    }
                }
            }

            .ExpandableContentShowMore {
                .Field {
                    .input-control {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            &-ExpandableContentHeading {
                pointer-events: none;
                font-size: 1.2rem;
                font-weight: 700;
                color: var(--body-content-color);
                letter-spacing: .06rem;
                text-transform: uppercase;

                &::after {
                    top: -3px !important;
                    transform: scale(.6) !important;
                    opacity: .5;
                }

                // @at-root .ExpandableContent-Button_isContentSelected & {
                //     font-weight: 800;
                // }

                &.CategoryMenu {
                    padding-bottom: 0;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 20px;
                    width: 20px;
                    background-image: url(icons/chevron-down.svg);
                    transform: rotate(0deg);
                    transition: all ease .3s;

                    @at-root .ExpandableContent-Button_isContentExpanded & {
                        transform: rotate(180deg);
                    }
                }
            }

            &-ExpandableContentSubHeading {
                display: none;
            }

            &-ExpandableContentContent {
                display: none;
                margin: 0;
                position: absolute;
                top: calc(100% - 1px);
                border: 1px solid var(--input-border-color);
                border-top: none;
                padding: 1.2rem 2.4rem;
                background: #fff;
                z-index: 2;
                box-shadow: rgba(50, 50, 93, .25) 0 6px 12px -2px, rgba(0, 0, 0, .3) 0 3px 7px -3px;
                width: auto;
                max-height: 40vh;
                overflow: auto scroll;
                left: 50%;
                transform: translateX(-50%);

                &.CategoryMenu {
                    padding-left: 2.4rem;

                    .CategoryMenuItem {
                        &-Item {
                            padding: 4px 0;

                            &:first-child {
                                padding-top: 0;
                            }
                        }

                        &-SubCategories {
                            margin-top: 0;

                            &::before {
                                border-left: 1px solid rgba(0, 0, 0, .05);
                            }

                            .CategoryMenuItem {
                                &-Item {
                                    padding: 2px 0;
                                }
                            }
                        }

                        &-Link {
                            &_active,
                            &_activeParent {
                                font-weight: 600;
                            }
                        }
                    }
                }

                &_isContentExpanded {
                    display: block;
                }
            }
        }
    }

    @at-root .ProductPage & {
        padding: 0;

        &-ExpandableContentHeading {
            font-size: var(--p-font-size);
            text-transform: uppercase;
            font-weight: var(--header-font-weight);
            color: var(--body-heading-color);
            text-align: left;
        }

        &-ExpandableContentSubHeading {
            margin-left: 5px;
            font-weight: var(--font-weight);
            font-size: 1rem;

            &::before {
                content: '- ';
            }

            &:empty {
                display: none;
            }
        }

        &-ExpandableContentButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            @include mobile {
                padding-top: 1.2rem;
                padding-bottom: 0;
                padding-left: 1.4rem;
                min-height: 0;
                flex-direction: row;
                justify-content: flex-start;
            }

            &::before,
            &::after {
                @include mobile {
                    display: none;
                }
            }
        }

        &-ExpandableContentContent {
            margin: 0;

            @include mobile {
                opacity: 1 !important;
                max-height: 100% !important;
                padding: 0 var(--product-configurable-attributes-content-padding) var(--product-configurable-attributes-content-padding) var(--product-configurable-attributes-content-padding) !important;
                margin: 0 -.7rem;
                overflow: visible;
            }

            .ProductConfigurableAttributeDropdown {
                margin: 0;
                width: auto;
                max-width: 100%;
            }
        }
    }

    // product page
    .ProductActions & {
        &-Expandable {
            margin-top: 1.2rem;
            padding-bottom: .6rem;

            @include desktop {
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, .1);
                margin-right: 0;
                margin-top: 2.4rem;
            }

            @include mobile {
                border-bottom: 1px solid var(--terciary-darkbg-color);
                max-width: calc(100% - 40px);
            }

            // &:last-child {
            //     @include mobile {
            //         border-bottom: 0;
            //     }
            // }
        }

        &-ExpandableContentButton {
            @include desktop {
                display: block;
                width: auto;
                // margin-right: 1rem;
            }

            @include mobile {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &-ExpandableContentHeading {
            @include desktop {
                text-transform: none;
                font-size: 1.4rem;
            }

            &::after {
                @include desktop {
                    content: '|';
                    display: inline-block;
                    position: relative;
                    bottom: 1px;
                    margin-left: 1rem;
                    color: #c3c3c3;
                }
            }
        }

        &-ExpandableContentSubHeading {
            display: none;
        }

        &-ExpandableContentContent {
            @include desktop {
                flex-grow: 1;
            }

            @include mobile {
                padding-right: 0 !important;
                padding-left: 0 !important;
                width: 100%;
                margin: 0;
            }

            .FieldSelect-Options {
                @include mobile {
                    background: #fff;
                }
            }

            .FieldSelect {
                &::after {
                    @include desktop {
                        right: 1.2rem;
                    }
                }

                &-Select {
                    color: #000;
                    background-color: transparent;
                    border: 0;
                    padding-left: 1rem;
                    padding-right: 0;
                    font-weight: bold;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    padding-left: 0;
                    font-size: 1.2rem;
                    font-weight: var(--font-weight);

                    @include mobile {
                        pointer-events: none;
                    }

                    @include desktop {
                        padding-left: 1.2rem;
                    }
                }

                &-Options {
                    top: calc(100% + 3px);
                    box-shadow: rgba(50, 50, 93, .25) 0 6px 12px -2px, rgba(0, 0, 0, .3) 0 3px 7px -3px;
                    border: none;
                    z-index: 100;

                    &_isExpanded {
                        @include mobile {
                            max-height: 40vh;
                        }
                    }
                }
            }
        }
    }
}
