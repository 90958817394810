.CategoryListPage {
    background-color: var(--terciary-darkbg-color);
    @include image-reset;
    height: 245px;
    margin-bottom: 3.6rem;

    @include mobile {
        height: auto;
    }

    &-Wrapper {
        @include wide-wrapper;
        @include mobile-padding;
        padding-top: 41px;
        margin-top: -41px;
        height: 100%;

        @include mobile {
            margin-top: 0;
            padding-top: 0;
        }
    }

    h1 {
        top: calc(50% - 20px);
        transform: translateY(-50%);
        text-transform: uppercase;

        @include mobile {
            padding: 2rem 0;
            transform: none;
            top: 0;
        }
    }

    .Image:not(.Logo) {
        @include mobile {
            display: none;
        }
        position: absolute;
        right: 0;
        top: 0;

        .Image-Image {
            max-height: 245px !important;
        }
    }
}
