.HeaderTopSection {
    height: var(--header-top-menu-height);
    margin: auto;
    max-width: none;
    display: flex;
    justify-content: space-between;
    
    p {
        font-size: 1.3rem;
    }

    a {
        color: var(--body-content-color); 
    }

    ul {
        display: flex;
        height: var(--header-top-menu-height);
        li {
            max-height: var(--header-top-menu-height);
            display: flex;
            padding-left: 0;
            margin-right: 2rem;
            margin-bottom: 0;
            align-items: center;
            &:after {
                content: '|';
                margin-left: 2rem;
                color: #c3c3c3;
            }
            &:before {
                content: '';
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
                &:after {
                    content: '';
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            p {
                margin-bottom: 0;
            }

            img {
                max-height: 17px;
                margin-right: 1rem;
            }

        }
    }

    &-TrustPilot {
        // required to show full iframe
        &:after {
            margin-left: 1.1rem!important;
        }
        > div {
            position: relative;
            top: 2px;
            min-width: 307px;
            left: -7px;
        }
    }


}