.slick-slider {
    .slick-arrow {
        width: 44px;
        height: 44px;
        background-color: #E3E1DD;
        z-index: 2;

        &::before {
            font-size: 0;
            display: block;
            height: 15px;
            width: 15px;
            margin: 0 auto;
        }

        &.slick-prev {
            left: 0!important;

            &::before {
                background-image: url(icons/chevron-left.svg);
            }
        }

        &.slick-next {
            right: 0!important;

            &::before {
                background-image: url(icons/chevron-right.svg);
            }
        }
    }

    .slick-list {
        margin: 0 -15px;
    }

    .slick-slide {
        padding: 0 15px;
    }

    .ProductCard {
        // padding: 0;
    }
}

.slick-slide {
    height: unset!important;
}

.slick-slider .slick-list .slick-track .slick-slide>div {
    height: 100%!important;
}

.slick-slider .slick-list .slick-track .slick-slide>div>div {
    height: 100%!important;
}

.slick-slider .ProductCard {
    height: 100%!important;
}

.slick-track {
    display: flex!important;
    align-items: stretch!important;
}