/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/mixins-custom';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Overlay {
    &.CategoryFilterOverlay {
        @include desktop {
            background-color: transparent;
        }
        z-index: 9;
        > div {
            @include desktop {
                background-color: transparent;
            }
        }
        @include desktop {
            padding: 2.4rem 0;
            padding-bottom: 1rem;
        }

        &_isVisible {
            @include mobile {
                padding: 1.4rem; 
                padding-top: 5rem;
                z-index: 99;
            }
        }

        .CategoryFilterOverlay-Heading {
            @include desktop {
                display: none;
            }
        }

    }
}