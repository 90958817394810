/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/mixins-custom';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.ClerkSearch {
    position: relative;
    width: 100%;
    margin-top: 1rem;
    display: none;
    top: 0;

    &_mobileActive {
        display: block;
    }

    @include desktop {
        position: absolute;
        left: 50%;
        margin-top: 0;
        width: auto;
        display: block;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &-Input {
        border-radius: 20px;
        padding: 1rem 5.5rem 1rem 3rem;
        background-color: var(--secondary-base-color);
        width: 100%;
        @include desktop {
            min-width: 500px;
            height: 40px;
        }

        &:active,
        &:focus {
            border: 1px solid var(--input-border-color);
        }


        &-Icon {
            width: 30px;
            height: 30px;
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(/../../hoeks-base/src/component/Header/icons/icon-search.svg);
            background-size: contain;
        }
    }

    &-Results {
        background: #fff;
        position: absolute;
        width: calc(100% - 40px);
        margin-top: 0;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid #ebe8e8;
        border-top: 0;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .2);
        z-index: 99;

        .SearchItem {
            padding: 1rem 0;
            margin: 0 1rem;
            border-bottom: 1px solid #ebe8e8;
        }

    }

}

.CategoryPage {
    &-Wrapper {
        @at-root .isSearchPage & {
            grid-template-columns: 1fr;
        }
    }
}
.CategoryProductList {
    &-Page{
        @at-root .isSearchPage & {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}